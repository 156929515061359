import variables from  '../_variables.scss';

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_URL_SITIO_INSTITUCIONAL = process.env.REACT_APP_BASE_URL_SITIO_INSTITUCIONAL
export const BASE_URL_API_CARTILLA = process.env.REACT_APP_BASE_URL_API_CARTILLA
export const COLORS = {
    color1: variables.color1,
    color2: variables.color2,
    color3: variables.color3,
    color4: variables.color4,
    color5: variables.color5,
    color6: variables.color6,
    color7: variables.color7,
}

