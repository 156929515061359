/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useParams } from 'react-router-dom';
import { fetch } from '../../utils/fetch';

import Template1Component from '../../components/templates/template1';
import Template2Component from '../../components/templates/template2';
import Template3Component from '../../components/templates/template3';
import Template4Component from '../../components/templates/template4';
import CommonLoader from '../../components/common-loader';

function PageTemplate() {
  const [dataResult, setDataResult] = React.useState(null);
  const params = useParams();
  const { slug } = params;
  const [loader, setLoader] = React.useState(true)

  React.useEffect(() => {
    setLoader(true)
    if (slug) {
      getPaginasById();
    }
  }, [slug]);

  const getPaginasById = async () => {
    try {
      const { data } = await fetch(`paginas?_slug=${slug}`, 'get');
      // console.log(data);
      setLoader(false)
      setDataResult(data[0]);
    } catch (error) {
      // console.error(error.message);
      setLoader(false)
    }
  };

  return (
    <>
      {loader ? 
        <CommonLoader/>
        :
        <div style={{minHeight: "calc(100vh - 25px - 100px - 225px)"}}>
          {dataResult
            && (
            <>
              {dataResult.template == 'template1' && <Template1Component children={dataResult} />}
              {dataResult.template == 'template2' && <Template2Component children={dataResult} />}
              {dataResult.template == 'template3' && <Template3Component children={dataResult} />}
              {dataResult.template == 'template4' && <Template4Component children={dataResult} />}
            </>
          )}
        </div>
      }
    </>
  );
}
export default PageTemplate;
