import React from "react";
import { Link, useParams } from "react-router-dom";
import { fetch } from "../../../utils/fetch";
import "./template-planes.scss";
import dompurify from "dompurify";
import { COLORS } from "../../../utils/contants";
import DynamicCommonLoader from "../../common-loader/dynamicCommonLoader";

const TemplatePlanesComponent = () => {
  const sanitizer = dompurify.sanitize;
  const [dataResult, setDataResult] = React.useState(null);
  const [color, setColor] = React.useState("");
  const [planes, setPlanes] = React.useState("");
  const params = useParams();
  const { slug } = params;
  const [loader, setLoader] = React.useState(true);

  const descripcionContainer = {
    background: color,
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (slug) {
      getPlanesBySlug();
      getPlanes();
    }
  }, [slug]);

  const getPlanesBySlug = async () => {
    try {
      let url = "";
      let isById = Number.isInteger(Number(slug));
      if (isById) url = `planes/${slug}?_locale=es&_publicationState=preview`;
      else url = `planes?_slug=${slug}&_locale=es&_publicationState=preview`;

      const { data } = await fetch(url, "get");
      // console.log("PlanesSL", data);
      setDataResult(isById ? data : data[0]);
      hexToRgbA(COLORS[isById ? data.color : data[0].color]);
      setLoader(false);
    } catch (error) {
      console.error(error.message);
      setLoader(false);
    }
  };

  const getPlanes = async () => {
    try {
      const { data } = await fetch(`planes?eliminado=false`, "get");
      const nuevosDatos = data.filter((objeto) => objeto.onOff !== false);
      setPlanes(nuevosDatos);
    } catch (error) {
      console.error(error.message);
    }
  };

  function hexToRgbA(hex) {
    // console.log('[Color]',hex);
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      setColor(
        "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          ",0.79)"
      );
    }
    //throw new Error('Bad Hex');
  }

  const openLinkInNewTab = (href) => {
    // console.log("log: 🚀  href :", href);
    window.open(href, "_blank");
  };

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"200px"} />
      ) : (
        <>
          {dataResult && (
            <>
              <div>
                <section className=" bg-planespage">
                  <div className="container-fluid descriptions-desktop">
                    <div className="row pt-5 pb-5 container-text-buttons">
                      <div className="col-lg-6 offset-lg-6 pe-0 ps-0 col-text-buttons">
                        <div
                          style={descripcionContainer}
                          className={`p-2 container-description`}
                        >
                          <div className="descripcion_plan p-4 text-white me-0 planBox">
                            <h2>{dataResult.nombre}</h2>
                            <p>{dataResult.descripcion}</p>
                          </div>
                        </div>
                        <div className="botones_container d-flex mt-1 ms-2 pb-1">
                          {planes &&
                            planes
                              .filter((data) => data.slug !== slug)
                              .map((data, index) => {
                                return (
                                  <Link
                                    to={`/planes/${data.slug}`}
                                    key={index}
                                    className={`btn1-${data.color}-r`}
                                  >
                                    {data.nombre}
                                  </Link>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="container-fluid descriptions-mobile">
                <div className="row container-text-buttons">
                  <div className="col-lg-6 offset-lg-6 pe-0 ps-0 col-text-buttons">
                    <div
                      style={descripcionContainer}
                      className={`p-2 container-description`}
                    >
                      <div className="descripcion_plan p-4 text-white me-0 ">
                        <h2>{dataResult.nombre}</h2>
                        <p>{dataResult.descripcion}</p>
                      </div>
                    </div>
                    <div className="botones_container d-flex mt-1 ms-2 pb-1">
                      {planes &&
                        planes
                          .filter((data) => data.slug !== slug)
                          .map((data, index) => {
                            return (
                              <Link
                                to={`/planes/${data.slug}`}
                                key={index}
                                className={`btn1-${data.color}-r`}
                              >
                                {data.nombre}
                              </Link>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>

              <section className="template-planes-contenido position-relative">
                <div className="container">
                  <div className="row mt-5 row-options">
                    <div className="col-lg-9">
                      <h2
                        style={{ fontSize: "45px" }}
                        className="global_title bold"
                      >
                        {dataResult.titulo}
                      </h2>
                      <hr
                        className={`hr${dataResult.color.replace("color", "")}`}
                      />
                      <div
                        className="container-right-buttons hb-red-buttons-mobile"
                        style={{
                          top: "0px",
                          marginTop: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        {dataResult.botones.map((data, index) => {
                          let href = "/" + data.url;
                          // console.log("REF BOTONES", href);
                          return (
                            <Link
                              key={index}
                              to={href}
                              type="button"
                              className="btn2-lateralr-color2"
                            >
                              {data.nombre}
                            </Link>
                          );
                        })}
                      </div>
                      <div className="contenido">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(dataResult.informacion, {
                              ADD_ATTR: ["target"],
                            }),
                          }}
                        />
                      </div>
                      <div className="container-right-buttons hb-red-buttons-desktop">
                        {dataResult.botones.map((data, index) => {
                          let href = "/" + data.url;
                          // console.log("REF BOTONES", href);
                          if (data.nombre == "Beneficios") {
                            return (
                              <button
                                type="button"
                                className="btn2-lateralr-color2"
                                onClick={() => openLinkInNewTab(href)}
                              >
                                {data.nombre}
                              </button>
                            );
                          } else {
                            return (
                              <Link
                                key={index}
                                to={href}
                                type="button"
                                className="btn2-lateralr-color2"
                              >
                                {data.nombre}
                              </Link>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};
export default TemplatePlanesComponent;
