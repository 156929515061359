import { Fab, ThemeProvider, createTheme } from "@mui/material";
import "./style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const WhatsappFloatComponent = () => {
  const theme = createTheme({
    palette: {
      whatsapp: {
        main: "#00BB2D",
        contrastText: "#fff",
      },
    },
  });

  const boxSX = {
    "&:hover": {
      backgroundColor: "#00BB2D",
    },
  };

  return (
    <div style={{ position: "fixed", bottom: 10, right: 15, zIndex: 2 }}>
      <div id="contenedor-whatsapp-desktop">
        <a rel="noreferrer" target="_blank" href="https://wa.me/541130159749">
          <ThemeProvider theme={theme}>
            <Fab
              color="whatsapp"
              sx={boxSX}
              variant="contained"
              aria-label="add"
            >
              <i class="bi bi-whatsapp" style={{ fontSize: 30 }}></i>
            </Fab>
          </ThemeProvider>
        </a>
      </div>
      <div id="contenedor-whatsapp-mobile">
        <a rel="noreferrer" target="_blank" href="https://wa.me/541130159749">
          <ThemeProvider theme={theme}>
            <Fab
              color="whatsapp"
              sx={boxSX}
              variant="contained"
              aria-label="add"
              className="btn-wpp-mobile"
            >
              <i class="bi bi-whatsapp" style={{ fontSize: 30 }}></i>
            </Fab>
          </ThemeProvider>
        </a>
      </div>
    </div>
  );
};

export default WhatsappFloatComponent;
