import React from 'react';
import './template2.scss'
import dompurify from 'dompurify';

const Template2Component = ({children}) => {
    // console.log('[Template 2]', children)
    const sanitizer = dompurify.sanitize; 
    const [contenido, setContenido] = React.useState(children.template2.sidemenu[0]);

    const handleChangeContenido = (data) => {
      setContenido(data)
    }
    const sectionBgStyle = {
      backgroundImage: `url(${(children.template2.fondo.url)})`,
      backgroundSize: 'cover',
      height: '300px',
      backgroundPosition:"center"
  }
return(
  <>
  <section style={sectionBgStyle}></section>
  <section className="template2-section position-relative">
    {/* Lateral Buttons */}
    <div className="container-right-buttons d-flex flex-column">
      {children.template2.sidemenu.map((data,index)=>{
        return(
          data.url == "#"
          ?
          <button type="button"  onClick={()=>{handleChangeContenido(data)}} className="btn2-lateralr-color2">{data.nombre}</button>
           :
           <a href={data.url}  className="btn2-lateralr-color2">{data.nombre}</a>
          
        )
      })}
     </div>
    <div className="container">
          <div className="row pt-5 pb-5">
              <div className="col-lg-9">
                  <h2 className="global_title bold">
                      {contenido.nombre}
                  </h2>
                  <hr className="hr6" />
                  <div className="contenido">
                  <div dangerouslySetInnerHTML={{__html: sanitizer(contenido.contenido,{ADD_ATTR: ['target']})}} />
                  </div>
              </div>
          </div>
      </div>
  </section>
  </>
);
}
export default Template2Component;