import * as Yup from 'yup';



export const validateModel = Yup.object().shape({

    nombre: Yup.string().required('Campo obligatorio'),
    apellido: Yup.string().required('Campo obligatorio'),    
    email: Yup.string().email('Email inválido').required('Campo obligatorio'),    
    celular: Yup.number().required('Campo obligatorio').typeError('Ingrese un numero valido'),    
    celular_prefix: Yup.number().required('Campo obligatorio').typeError('Ingrese un numero'),        
    
    direccion: Yup.string().required('Campo obligatorio'),    
    numero: Yup.number().required('Campo obligatorio').typeError('Ingrese un numero valido'),   
    dni: Yup.number().required('Campo obligatorio').typeError('Ingrese un numero valido'),   
    no_socio:Yup.number().required('Campo obligatorio').typeError('Ingrese un numero valido'),    
    localidad: Yup.string().required('Campo obligatorio'), 
    cp: Yup.number().required('Campo obligatorio').typeError('Ingrese un numero valido'),   
    mensaje: Yup.string().required('Campo obligatorio'),        

    aceptoPrivacidad: Yup.bool().required('Campo obligatorio'),        
    validateAceptoPrivacidad: Yup.string().when('aceptoPrivacidad', {
        is: false,
        then: Yup.string().required("Campo obligatorio")
    })       
});

