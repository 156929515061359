import React, { useState, useContext, useRef,memo } from "react";
import "./navbar-mobile.scss";
import logo from "../../assets/img/logo.png";
import { fetch } from "../../utils/fetch";
import { Link } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../context/globalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import imgWhatsapp from "../../assets/img/Whatsapp.png";

const NavbarMobileComponent = (props) => {
  const { HeroSectionFetchData } = React.useContext(GlobalContextMemorySpace);

  const navToggle = useRef(null);
  const navMenu = useRef(null);
  const [open, setOpen] = useState(false);

  const [lang, setLang] = React.useState("es");
  const [menus, setMenus] = React.useState([]);
  const [currentDropdown, setCurrentDropdown] = React.useState(null);
  // const [destacado, setDestacado] = React.useState(null);

  const onClick = (index) => {
    setCurrentDropdown(index === currentDropdown ? null : index);
  };
  const [isShown, setIsShown] = useState(false);

  const hideDropdown = () => {
    setCurrentDropdown(null);
    setOpen(false);
  };

  // const botonesDestacada=async ()=>{
  //   const { data } = await fetch("home-slides?onOff=true&eliminado=false&_sort=orden:ASC", "GET");
  //   var item = data?.filter((item) => item.destacado);
  //   if (item == null) {            
  //     return;
  //     // console.log("ITEM", item[0]);
  //   }

  //   if(item[0]==null)
  //   {
  //     return;
  //   }

  //   setDestacado(item[0]);
  // }

  React.useEffect(() => {    
    // botonesDestacada();    

    const getMenuItems = async () => {
      const { data } = await fetch(
        `menus?_locale=${lang}&eliminado=false`,
        "GET"
      );
      setMenus(data);
    };

    setMenus(props.NavBarSectionFetchData);

    //getMenuItems()
  }, [lang, props.NavBarSectionFetchData]);

  const menuVertical = () => {
    setOpen((previo) => !previo);
    setCurrentDropdown(null);
  };

  return (
    <>
      <header className="header">
        <nav className={`nav ${open ? "nav-whithout-shadow" : ""}`}>
          <div
            id="topbar"
            style={{ height: "40px", maxHeight: "40px", width: "100%" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 font-header-mobile d-flex justify-content-center flex-column align-items-center">
                  <span style={{fontSize:"10px"}}>Urgencias y Emergencias:</span>{" "}
                  <a
                    style={{ color: "inherit",fontSize:"11px",fontWeight:"bold" }}
                    rel="noreferrer"
                    target="_blank"
                    href="tel:01143096633"
                  >
                    (011) 4309-6633/4
                  </a>
                </div>               
              </div>
            </div>
          </div>

          <Link className="logo nav-link-mobile" to={"/"}>
            <img
              style={{ height: "50px" }}
              src={logo}
              alt="logo"
              className=""
            />
          </Link>
          <button
            className="nav-toggle"
            ref={navToggle}
            onClick={() => menuVertical()}
          >
            <FontAwesomeIcon icon={faBars} color="#004890" />
          </button>
          <ul
            className={`nav-menu ${open ? "nav-menu_visible" : ""}`}
            ref={navMenu}
          >
            {menus &&
              menus.map((data, index) => {
                const isSubmenu = data.submenu.length > 0;
                return isSubmenu ? (
                  <li className="nav-menu-item" key={"a" + index}>
                    <div className="menu-mobile" onClick={() => onClick(index)}>
                      <span className="nav-menu-link nav-link-mobile">
                        {data.menu.nombre}
                      </span>
                      <FontAwesomeIcon icon={faAngleDown} color="#004890" />
                    </div>
                    <hr style={{ margin: "0px" }} />
                    <ul
                      className="subMenu-mobile"
                      onClick={() => hideDropdown()}
                      style={
                        currentDropdown === index
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {data.submenu.map((submenuData, index2) => {
                        return submenuData.url
                          .toLowerCase()
                          .startsWith("http") ? (
                          <li key={index2} className="nav-menu-item subMenu-li">
                            <a
                              href={submenuData.url}
                              target="_blank"
                              className="nav-menu-link nav-link-mobile subMenu-item"
                            >
                              {submenuData.nombre}
                            </a>
                            <hr
                              style={{ margin: "0px", marginRight: "10px" }}
                            />
                          </li>
                        ) : (
                          <li key={index2} className="nav-menu-item subMenu-li">
                            <Link
                              className="nav-menu-link nav-link-mobile subMenu-item"
                              to={submenuData.url}
                            >
                              {submenuData.nombre}
                            </Link>
                            <hr
                              style={{ margin: "0px", marginRight: "10px" }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : data.menu.url.toLowerCase().startsWith("http") ? (
                  <li
                    className="nav-menu-item"
                    key={index}
                    onClick={() => hideDropdown()}
                  >
                    <div className="menu-mobile">
                      <a
                        href={data.menu.url}
                        target="_blank"
                        className="nav-menu-link nav-link-mobile"
                      >
                        {data.menu.nombre}
                      </a>
                    </div>
                    <hr style={{ margin: "0px", marginRight: "0px" }} />
                  </li>
                ) : (
                  <li
                    className="nav-menu-item"
                    key={index}
                    onClick={() => hideDropdown()}
                  >
                    <div className="menu-mobile">
                      {/* <span as={Link} to={data.menu.url} className="nav-menu-link nav-link-mobile">{data.menu.nombre}</span> */}
                      <Link
                        target="_blank"
                        className="nav-menu-link nav-link-mobile"
                        to={data.menu.url}
                      >
                        {data.menu.nombre}
                      </Link>
                    </div>
                    <hr style={{ margin: "0px", marginRight: "0px" }} />
                  </li>
                );
              })}

            {/* <li className="nav-menu-item"><a href="#" className="nav-menu-link nav-link">Información al socio</a></li>
                <li className="nav-menu-item"><a href="#" className="nav-menu-link nav-link">Novedades</a></li>
                <li className="nav-menu-item"><a href="#" className="nav-menu-link nav-link">Hospital Britanico</a></li>
                <li className="nav-menu-item"><a href="#" className="nav-menu-link nav-link">Contacto</a></li> */}

            
          </ul>
        </nav>
      </header>
    </>
  );
};
export default NavbarMobileComponent;
