import React from "react";
import "./planes.scss";
// import bg from '../../assets/img/bg-planes.png';
// import bg from "../../assets/img/banner_planes1.jpg"
import bg from "../../assets/img/banner_planes2.jpg";
import { fetch } from "../../utils/fetch";
import { COLORS } from "../../utils/contants";
import { Link } from "react-router-dom";
import ArrowWhite from "../../assets/img/arrow_white.png";
import CommonLoader from "../../components/common-loader";
import { useNavigate } from "react-router-dom";

const PlanesPage = () => {
  const [planes, setPlanes] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const navigate = useNavigate();

  // const getContenido = async () => {
  //   try {
  //     const { data } = await fetch("planes", "GET");
  //     console.log("[Planes]", data);
  //     console.log("[Color]", data.color);
  //     setPlanes(data);
  //   } catch (error) {}
  // };

  React.useEffect(() => {
    fetch(`planes?eliminado=false`, "GET")
      .then((res) => {
        const nuevosDatos = res.data.filter((objeto) => objeto.onOff !== false);
        setPlanes(nuevosDatos);
      })
      .catch((error) => {
        setLoader(false);
        /*renderizo un error generico*/
        // console.log(error)
      })
      .finally(() => {
        setLoader(false);
      });

    //getContenido();
  }, []);

  const verDetalle = (url) => {
    navigate(url);
  };

  return (
    <section>
      {/* <div className="container-fluid">
        <div className="row p-0">
          <div className="col-lg-12 p-0">
            <img
              src={bg}
              alt="..."
              style={{ width: "100%", height: "55vh", objectFit: "cover" }}
              //className="img-fluid"
            />
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12">
            <h2 className="global_title bold">Conocé nuestros planes</h2>
            <hr className="hr6" />
            {/*<div className="contenido">
              <p>
                Nuestra prioridad es cuidar la salud de nuestros socios, desde
                el PSHB privilegiamos un seguimiento cercano, con excelencia
                medica de Hospital Británico y su red de Centros Médico. De esta
                forma podemos atenderlos personalmente en nuestras
                instalaciones, con nuestros profesionales y la calidad y
                trayectoria que nos caracteriza hace más de 175 años. Para
                complementar nuestra cobertura se pone a disposición del socio
                una cartilla de prestadores.
              </p>
            </div> */}
          </div>
        </div>

        {loader ? (
          <CommonLoader marginTop="-20vh" />
        ) : (
          <div className="row mt-4">
            {planes &&
              planes.map((data, index) => {
                const dinamycBg = { background: COLORS[data.color] };
                return (
                  <div className="col-lg-4 mb-4" key={index}>
                    <div
                      className="card b-radius-18 border-0"
                      style={dinamycBg}
                      id="card-planes"
                      onClick={() => verDetalle("/planes/" + data.slug)}
                    >
                      <div className="card-body">
                        <img
                          src={data.miniatura.url}
                          alt="..."
                          className="img-fluid b-radius-20 border-0"
                        />
                        <div className="card-title">
                          <h3 className="text-white mt-2 ff-Objectivity-Bold">
                            {data.nombre}
                          </h3>
                        </div>
                        <div className="text-white ff-Objectivity-Regular">
                          {data.descripcion}
                        </div>
                      </div>
                      <div className="card-footer pb-3 bg-transparent border-0">
                        <div className="d-flex justify-content-end">
                          <Link
                            className="ff-Objectivity-Regular"
                            style={{ textDecoration: "none", color: "white" }}
                            to={"/planes/" + data.slug}
                          >
                            Ver más{" "}
                            <img
                              src={ArrowWhite}
                              alt="..."
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </section>
  );
};
export default PlanesPage;
