/* eslint-disable react-hooks/exhaustive-deps */
import dompurify from "dompurify";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import cruz from "../../../assets/img/cruz.png";
import { fetch } from "../../../utils/fetch";
import "./novedadesview.scss";

const VerNovedadesPage = () => {
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { slug } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    /*const getData = async () => {
            console.log("this")
            //console.log(props.location.aboutProps)
            console.log(slug)
            const {data} = await fetch(`novedades?_slug=${slug}&_locale=es`, 'GET' );
            console.log("this")
            setContenido(data[0])
            console.log(contenido.imagen.url)
        }
        getData()*/

    let url = "";
    let isById = Number.isInteger(Number(slug));
    if (isById) url = `novedades/${slug}?_locale=es&_publicationState=preview`;
    else url = `novedades?_slug=${slug}&_locale=es&_publicationState=preview`;

    fetch(url, "GET")
      .then((res) => {
        setContenido(isById ? res.data : res.data[0]);
      })
      .catch((error) => {
        setLoader(false);
        /*renderizo un error generico*/
        // console.log(error)
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  return (
    <section style={{ minHeight: "calc(100vh - 25px - 100px - 225px)" }}>
      <div className="container">
        <div className="row pt-5 pb-5">
          <div className="col-lg-12">
            <div
              className="card card-como-llegar p-5 b-radius-10"
              id="card-novedades"
            >
              <div className="row">
                {/* {!contenido?.destacado && */}
                <div className="col-12 col-lg-5">
                  <div className="d-none d-lg-block">
                    <img
                      className="rounded"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "600px",
                        maxHeight: "250px",
                      }}
                      src={contenido?.imagen?.url}
                    />
                  </div>
                </div>
                {/* } */}

                <div className={"col-12 col-lg-7"}>
                  <div className="card-header bg-transparent border-0 d-flex justify-content-between p-0 py-3">
                    {/* PC */}
                    <div className="d-none d-lg-block">
                      <span style={{ color: "#185a9a", fontSize: "26px" }}>
                        Novedades |{" "}
                        <Link
                          to="/novedades"
                          style={{ color: "#99ca3e", textDecoration: "none" }}
                        >
                          <b>Ver todas</b>
                        </Link>
                      </span>
                    </div>

                    {/* MV */}
                    <div className="d-block d-lg-none">
                      <div style={{ color: "#185a9a", fontSize: "16px" }}>
                        Novedades
                      </div>
                      <Link
                        to="/novedades"
                        style={{
                          color: "#185a9a",
                          textDecoration: "none",
                          fontSize: "16px",
                        }}
                      >
                        <b>Ver todas</b>
                      </Link>
                    </div>

                    <div style={{ position: "absolute", top: 40, right: 40 }}>
                      <img
                        onClick={() => navigate(-1)}
                        src={cruz}
                        alt="navigate"
                      />
                    </div>
                  </div>

                  <h2 className="global_title fw700">
                    {contenido && contenido.titulo}
                  </h2>

                  {/* {!contenido?.destacado && */}
                  <div className="d-block d-lg-none">
                    <img
                      className="rounded"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "600px",
                        maxHeight: "250px",
                      }}
                      src={contenido?.imagen?.url}
                    />
                  </div>
                  {/* } */}
                </div>
              </div>

              <hr className="hr6" />

              {loader ? (
                <div
                  style={{ padding: "10px" }}
                  className="d-flex justify-content-center"
                >
                  <div className="spinner-grow text-muted"></div>
                  <div
                    style={{ marginLeft: "5px" }}
                    className="spinner-grow text-muted"
                  ></div>
                  <div
                    style={{ marginLeft: "5px" }}
                    className="spinner-grow text-muted"
                  ></div>
                </div>
              ) : (
                <div className="contenido">
                  <small>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          contenido && contenido.descripcionCorta,
                          { ADD_ATTR: ["target"] }
                        ),
                      }}
                    />
                  </small>
                  <br />
                  <div
                    className="scrollable-table"
                    style={{ textAlign: "justify" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(contenido && contenido.descripcion, {
                          ADD_ATTR: ["target"],
                        }),
                      }}
                    />
                    {/* <div className='container-img'>
                                            {contenido.imagen.url ? <img src={contenido.imagen.url} style={{ width: '100%' }}></img> : null}
                                        </div> */}

                    {/* {
                                            contenido?.destacado && <div><img style={{ width: '100%', objectFit: 'cover',maxWidth:"600px", maxHeight:"250px" }} src={contenido?.imagen?.url} /></div>
                                        } */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default VerNovedadesPage;
