/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

import IconFb from "../../assets/img/redes/Facebook.png";
import IconInsta from "../../assets/img/redes/Instagram.png";
import IconLinkedin from "../../assets/img/redes/LinkedIn.png";
import IconTw from "../../assets/img/redes/Xfooter.svg";

import FooterLocationPin from "../../assets/img/footer-location-pin.png";
import FooterPhone from "../../assets/img/footer-phone.png";
import { useToggle } from "../../utils/useToggle";
import { PoliticaProteccionComponent } from "./politica-proteccion";
import LogoFooter from "../../assets/img/logo-hb-blak.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faBook,
  faCircleXmark,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { GlobalContextMemorySpace } from "../../context/globalContext";
const FooterComponent = () => {
  const politicaToggle = useToggle();

  const { FooterSectionFetchData } = useContext(GlobalContextMemorySpace);

  function ScrollTop() {
    window.scrollTo({ top: 0, left: 0, beahivor: "smooth" });
  }

  return (
    <section className="section-footer" style={{ minHeight: "200px" }}>
      <div className="container">
        <div className="row pt-3 pb-2">
          <div className="col-lg-6">
            <p className="d-flex justify-content-start align-items-center">
              <img
                src={LogoFooter}
                alt="logo footer"
                style={{ paddingRight: "5px", height: "16px" }}
              />
              <span className="section-footer__titulo">
                {" "}
                Hospital Británico | © 2022
              </span>
            </p>
            <p className="section-footer__texto">
              Sr. consumidor o usuario: ante cualquier duda o reclamo dirijase
              al área de defensa y protección al consumidor de cualquier Centro
              de Gestión y Participación Comunal (CGPC) del Gobierno de la
              Ciudad Autónoma de Bs. As. y/o al teléfono gratuito 147 del
              Gobierno de la Ciudad Autónoma de Buenos Aires.R.N.E.M.P Nº 1255
              -Superintendencia de Servicios de Salud -0800-222-SALUD (72583){" "}
              <a href="https://www.argentina.gob.ar/sssalud" target="_blank">
                {" "}
                www.sssalud.gob.ar{" "}
              </a>
            </p>
            <p className="section-footer__texto">
              Defensa de las y los consumidores. Para reclamos ingrese{" "}
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario"
              >
                aquí.
              </a>
            </p>
          </div>
          <div className="col-lg-6">
            <div className="d-flex contact-social-networks">
              <div className="icons d-flex">
                <a href={FooterSectionFetchData?.facebook} target="_blank">
                  <img
                    src={IconFb}
                    className="img-fluid1 px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a href={FooterSectionFetchData?.twitter} target="_blank">
                  <img
                    src={IconTw}
                    className="img-fluid1 px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a href={FooterSectionFetchData?.linkedin} target="_blank">
                  <img
                    src={IconLinkedin}
                    className="img-fluid1 px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a href={FooterSectionFetchData?.instagram} target="_blank">
                  <img
                    src={IconInsta}
                    className="img-fluid1 px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
              </div>
              <div className="section-footer__privacidad">
                <span className="section-footer__texto">
                  <span>
                    <button
                      id="btn-privacidad"
                      className="btn"
                      onClick={politicaToggle.onToggle}
                    >
                      POLITICA DE PRIVACIDAD
                    </button>
                  </span>
                  <span>
                    {" "}
                    <button className="btn btnPointer">
                      {" "}
                      | MAPA DEL SITIO
                    </button>
                  </span>
                  <span>
                    {" "}
                    <button className="btn btnPointer">
                      {" "}
                      |{" "}
                      <a
                        style={{ color: "inherit" }}
                        target={"_blank"}
                        className="link-default"
                        rel={"noreferrer"}
                        href={FooterSectionFetchData?.intranet}
                      >
                        INTRANET
                      </a>
                    </button>
                  </span>
                </span>
                <PoliticaProteccionComponent
                  visible={politicaToggle.toggle}
                  onToggle={politicaToggle.onToggle}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex flex-column mx-2 mt-3 contact-information">
                  <div className="icon-location">
                    <img
                      src={FooterLocationPin}
                      className="img-fluid"
                      alt="icon-fb"
                      style={{}}
                    />
                    <span className="ms-2 section-footer__texto">
                      {/* Perdriel 74 - CP 1280AEB - Ciudad Autónoma de Buenos Aires */}
                      {FooterSectionFetchData?.direccion_domicilio}
                    </span>
                  </div>
                  <div className="icon-phone">
                    <img
                      src={FooterPhone}
                      className="img-fluid"
                      alt="icon-fb"
                      style={{}}
                    />
                    <a href={FooterSectionFetchData?.telefono_domicilio}>
                      <span className="ms-2 section-footer__texto">
                        {FooterSectionFetchData?.telefono_domicilio}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column mx-2 mt-3 contact-information">
                  <div className="icon-book">
                    <FontAwesomeIcon icon={faBook} color="#0f86c3" />
                    <span
                      onClick={() => {
                        ScrollTop();
                      }}
                      className="ms-2 section-footer__texto"
                    >
                      <Link to="pages/libro-de-sugerencias">
                        Libro de sugerencias
                      </Link>
                    </span>
                  </div>
                  <div className="icon-book">
                    <FontAwesomeIcon icon={faDownload} color="#0f86c3" />
                    <span
                      onClick={() => {
                        ScrollTop();
                      }}
                      className="ms-2 section-footer__texto"
                    >
                      <Link to="pages/baja-plan-salud">
                        Solicitud de Baja de Plan de Salud
                      </Link>
                    </span>
                  </div>
                  <div className="icon-book">
                    <FontAwesomeIcon icon={faCircleXmark} color="#0f86c3" />
                    <span
                      onClick={() => {
                        ScrollTop();
                      }}
                      className="ms-2 section-footer__texto"
                    >
                      <Link to="pages/tramite-arrepentimiento">
                        Trámite de arrepentimiento
                      </Link>
                    </span>
                  </div>
                  <div className="icon-book">
                    <FontAwesomeIcon icon={faFile} color="#0f86c3" />
                    <span className="ms-2 section-footer__texto">
                      <Link
                        to="Solicitud_De_Ingreso.pdf"
                        target="_blank"
                        download
                      >
                        Contrato de Adhesión
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FooterComponent;
