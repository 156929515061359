import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './utils.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { GlobalContext } from './context/globalContext';
import { ErrorBoundary } from './components/exception/exception';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>            
        <BrowserRouter>                
            <App />             
        </BrowserRouter>              
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
