/* eslint-disable react-hooks/exhaustive-deps */
import dompurify from 'dompurify';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cruz from '../../../assets/img/cruz.png';
import { fetch } from '../../../utils/fetch';
import './promocionesview.scss';

const VerPromocionesPage = () => {

    const sanitizer = dompurify.sanitize;
    const [contenido, setContenido] = React.useState(null)
    const [loader, setLoader] = React.useState(true)
    let { id,idPlan } = useParams();

    React.useEffect(() =>{
        window.scrollTo(0, 0);
      },[]);

    let { slug } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        /*const getData = async () => {
            console.log("this")
            //console.log(props.location.aboutProps)
            console.log(slug)
            const {data} = await fetch(`novedades?_slug=${slug}&_locale=es`, 'GET' );
            console.log("this")
            setContenido(data[0])
            console.log(contenido.imagen.url)
        }
        getData()*/
        
        
        let url = `planes/${idPlan}?_locale=es`        

        fetch(url, 'GET')
            .then((res) => {                
                setContenido({color:res.data.color,promocion:res.data.promociones.find(promo=>promo.promocion.id==id && !promo.promocion.eliminado && promo.promocion.onOff).promocion})
            })
            .catch((error) => {
                setLoader(false)
                /*renderizo un error generico*/
                // console.log(error)
            })
            .finally(() => {
                setLoader(false)
            })
    }, [])



    return (
        <section style={{minHeight: "calc(100vh - 25px - 100px - 225px)"}} className={contenido?.color} >
            
            <div className="container">
                <div className="row pt-5 pb-5">

                    <div className="col-lg-12">
                        <div className="card card-como-llegar p-5 b-radius-10" id="card-promociones">

                            <div className='row'>                                

                                <div className={'col-12 col-lg-12'}>
                                    <div className="card-header bg-transparent border-0 d-flex justify-content-between p-0 py-3">

                                        {/* PC */}
                                        <div className='d-none d-lg-block'>
                                            <span style={{ color: '#185a9a', fontSize: '26px' }}>Promociones  <Link to={"/promociones/"+idPlan} style={{ color: '#99ca3e', textDecoration: 'none' }}><b>Ver todas</b></Link></span>
                                        </div>

                                        {/* MV */}
                                        <div className='d-block d-lg-none'>
                                            <div style={{ color: '#185a9a', fontSize: '16px' }}>Promociones</div>
                                            <Link to="/promociones" style={{ color: '#185a9a', textDecoration: 'none', fontSize: '16px' }}><b>Ver todas</b></Link>
                                        </div>

                                        <div style={{ position: 'absolute', top: 20, right: 25 }}>
                                            <img onClick={() => navigate(-1)} src={cruz} alt="navigate" />
                                        </div>


                                    </div>                                                                            
                                    
                                </div>

                            </div>

                            <hr className="hr-promociones" />

                           

                            {loader ?
                                <div style={{ padding: "10px" }} className='d-flex justify-content-center'>
                                    <div className="spinner-grow text-muted"></div>
                                    <div style={{ marginLeft: "5px" }} className="spinner-grow text-muted"></div>
                                    <div style={{ marginLeft: "5px" }} className="spinner-grow text-muted"></div>
                                </div>
                                :
                                <div>
                                     {/* <div className='d-block'>                                            
                                            <img className='' style={{width: '100%'}} src={contenido?.promocion?.imagen?.url} />
                                    </div> */}
                                    <div className="contenido" style={{textAlign:"justify"}}>
                                        {/* <small>
                                            <div  dangerouslySetInnerHTML={{ __html: sanitizer(contenido?.promocion?.titulo) }} />
                                        </small>
                                        <br /> */}

                                        <div dangerouslySetInnerHTML={{ __html: sanitizer(contenido?.promocion?.descripcion,{ADD_ATTR: ['target']}) }} />
                                        {/* <div className='container-img'>
                                            {contenido.imagen.url ? <img src={contenido.imagen.url} style={{ width: '100%' }}></img> : null}
                                        </div> */}

                                        {/* {
                                            contenido?.destacado && <div><img style={{ width: '100%', objectFit: 'cover',maxWidth:"600px", maxHeight:"250px" }} src={contenido?.imagen?.url} /></div>
                                        } */}
                                    </div>


                                </div>
                            }

                        </div>

                    </div>
                </div>
            </div>
        </section>

    );
}
export default VerPromocionesPage;