import contacto from "../../assets/img/bg-dejanos-tu-consulta.png";
import "./afiliarme.scss";
import React from "react";

const AfiliarmePage = () => {
  React.useEffect(() => {
    const salesiqWidget = window.$zoho;
    salesiqWidget.salesiq.floatbutton.visible("show");

    return () => {
      salesiqWidget.salesiq.floatbutton.visible("hide");
    };
  }, []);

  return (
    <section className="section_contacto">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-5 section_contacto_form">
            <h2 className="subtitle-global">
              <b>Afiliate a Nuestro Plan de Salud</b>
            </h2>
            <hr className="hr6" style={{ borderTop: "0px" }} />
            <iframe
              src="/static/afiliarme.html"
              width="100%"
              height="650"
            ></iframe>
          </div>
          <div className="col">
            <img src={contacto} className="img-contacto" alt="bg-contacto" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AfiliarmePage;
