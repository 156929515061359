import "./navbar.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import useSortMenu from "../../hooks/useSortMenu";
import imgWhatsapp from "../../assets/img/Whatsapp.png";
import logo from "../../assets/img/logo.png";
import { fetch } from "../../utils/fetch";

const NavbarComponent = (props) => {
  const [lang, setLang] = React.useState("es");
  const [currentDropdown, setCurrentDropdown] = React.useState(null);
  const { menus } = useSortMenu(lang, props.NavBarSectionFetchData);
  const [contacto, setContacto] = React.useState({});

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await fetch("datos-contactos", "get");
    setContacto(data[0]);
  };

  return (
    <>
      <div id="topbar" style={{ height: "25px", maxHeight: "25px" }}>
        <div className="container">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="p-2 fontHeader">
              Plan de Salud {contacto?.planSalud}
            </div>
            <div className="p-2 fontHeader">
              Urgencias y Emergencias: {contacto?.urgencias}
            </div>
            <div className="p-2 fontHeader" style={{ marginRight: "10px" }}>
              Gestioná tus consultas y trámites{" "}
              <img className="whatsapp" src={imgWhatsapp} />
              {contacto?.tramites}
            </div>
          </div>
        </div>
      </div>
      <Navbar
        bg="white"
        expand="lg"
        className="p-0 nav-principal"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        <Container style={{ height: "100px" }}>
          <Link className="" to="/">
            <img
              style={{ height: "80px" }}
              src={logo}
              alt="logo"
              className=""
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
            style={{ backgroundColor: "white" }}
          >
            <Nav>
              {menus &&
                menus.map((data, index) => {
                  const isSubmenu = data.submenu.length > 0;
                  return isSubmenu ? (
                    <div
                      className="dropdown"
                      onMouseLeave={() => setCurrentDropdown(null)}
                      onMouseEnter={() => setCurrentDropdown(data.menu.nombre)}
                      key={index}
                      title={data.menu.nombre}
                    >
                      <button className="dropbtn fontMenu">
                        {data.menu.nombre}
                      </button>
                      <div
                        className="dropdown-content"
                        style={
                          currentDropdown === data.menu.nombre
                            ? { display: "block", width: "100%" }
                            : { display: "none" }
                        }
                      >
                        <div className="d-flex justify-content-center">
                          <div>
                            {data.submenu.map((submenuData, index2) => {
                              return submenuData.url
                                .toLowerCase()
                                .startsWith("http") ? (
                                <a
                                  onClick={() => setCurrentDropdown(null)}
                                  className="anchorWithoutSubmenu fontMenu"
                                  key={index2}
                                  href={submenuData.url}
                                  target="_blank"
                                >
                                  {submenuData.nombre}
                                </a>
                              ) : (
                                <Link
                                  key={index2}
                                  onClick={() => setCurrentDropdown(null)}
                                  className="anchorWithoutSubmenu fontMenu"
                                  to={submenuData.url}
                                >
                                  {submenuData.nombre}
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : data.menu.url.toLowerCase().startsWith("http") ? (
                    <button
                      className="dropbtn sin-submenu"
                      key={index}
                      onClick={() => {
                        window.open(data.menu.url, "_blank");
                      }}
                    >
                      <a
                        className="anchorWithoutSubmenuMain fontMenu"
                        target="_blank"
                        href={data.menu.url}
                      >
                        {data.menu.nombre}
                      </a>
                    </button>
                  ) : (
                    <button className="dropbtn sin-submenu" key={index}>
                      <Link
                        target="_blank"
                        className="anchorWithoutSubmenuMain fontMenu"
                        to={data.menu.url}
                      >
                        {data.menu.nombre}
                      </Link>
                    </button>
                  );
                })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default NavbarComponent;
