import * as React from "react";

import "./planes-section.scss";
import Slider from "react-slick";
import { fetch } from "../../../../utils/fetch";
import { COLORS } from "../../../../utils/contants";
import { Link } from "react-router-dom";
import CommonLoader from "../../../common-loader/index";
import { GlobalContextMemorySpace } from "../../../../context/globalContext";

const PlanesSection = () => {
  const { PlanesSectionFetchData } = React.useContext(GlobalContextMemorySpace);
  const [body, setBody] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const getContenido = async () => {
    try {
      const { data } = await fetch("planes", "GET");
      // console.log("[Planes]", data);
      // console.log("[Color]", data.color);
      setBody(data);
    } catch (error) {}
  };

  function hexToRgbA(hex) {
    // console.log('[Color]',hex);
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      //setColor('rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.79)');
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        ",0.79)"
      );
    }
    //throw new Error('Bad Hex');
  }

  React.useEffect(() => {
    //getContenido();
    setBody(PlanesSectionFetchData);
  }, [PlanesSectionFetchData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {body && (
        <section className="section-planes">
          <div className="container">
            <h2 className="global_title pt-5">Nuestros Planes</h2>
            <div className="row pt-3">
              <Slider {...settings}>
                {body &&
                  body.map((data, index) => {
                    const dinamycBg = {
                      // width: "400px",
                      background: COLORS[data.color],
                    };
                    const captionStyle = {
                      background: hexToRgbA(COLORS[data.color]),
                      position: "absolute",
                      bottom: "40px",
                      right: "0px",
                      padding: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 0,
                      right: 0,
                      width: "60%",
                      color: "white",
                      fontFamily: "Objectivity-Regular",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                    };
                    return (
                      // <div key={index}>
                      //   <div
                      //     className="position-relative b-radius-10"
                      //     style={{
                      //       width: "300px",
                      //       // border: "10px solid",
                      //       paddingTop: "10px",
                      //       paddingRight: "10px",
                      //       paddingLeft: "10px",
                      //       margin: "auto",
                      //       backgroundColor: captionStyle.background, // Color del borde
                      //     }}
                      //   >
                      //     <Link to={"/planes/" + data.slug}>
                      //       <img
                      //         src={data.miniatura.url}
                      //         alt="imagen"
                      //         className="img-fluid b-radius-10"
                      //         // style={{
                      //         //   border: "10px solid green", // Agregar un borde de color verde
                      //         //   // borderRadius: "10px",
                      //         //   borderColor: captionStyle.background,
                      //         // }}
                      //       />
                      //       {/* <div style={captionStyle}> */}
                      //       <center>
                      //         <span
                      //           style={{
                      //             color: "white",
                      //             fontSize: "25px",
                      //             fontFamily: "Objectivity-Regular",
                      //           }}
                      //           className="text-center"
                      //         >
                      //           {data.nombre}
                      //         </span>
                      //       </center>
                      //       {/* </div> */}
                      //     </Link>
                      //   </div>
                      // </div>
                      <div className="mb-4 p-2" key={index}>
                        <div
                          className="card b-radius-18 border-0"
                          style={dinamycBg}
                          id="card-planes"
                          // onClick={() => verDetalle("/planes/" + data.slug)}
                        >
                          <Link to={"/planes/" + data.slug}>
                            <div className="card-body">
                              <center>
                                <img
                                  src={data.miniatura.url}
                                  alt="..."
                                  className="img-fluid b-radius-20 border-0"
                                />
                              </center>
                              <div className="card-title">
                                <h3 className="text-white mt-2 ff-Objectivity-Bold">
                                  {data.nombre}
                                </h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PlanesSection;
