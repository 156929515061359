import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import CommonLoader from "../../components/common-loader";
import Loader from "../../components/loader";
//import BotonesSection from '../../components/pages/home/botones-section';
//import CanalesAtencionSection from '../../components/pages/home/canales-atencion-section';
//import CredencialSection from '../../components/pages/home/credencial-section';
//import HeroSection from '../../components/pages/home/hero-section';
//import LogosComponent from '../../components/pages/home/logos';
//import NovedadesSection from '../../components/pages/home/novedades-section';
//import PlanesSection from '../../components/pages/home/planes-section';
//import TeleconsultasSection from '../../components/pages/home/teleconsultas-section';
import { GlobalContextMemorySpace } from "../../context/globalContext";
import WhatsappFloatComponent from "../../components/whatsapp";
import { PopUpComponent } from "../../components/pop-up";

const HeroSection = lazy(() =>
  import("../../components/pages/home/hero-section")
);
const BotonesSection = lazy(() =>
  import("../../components/pages/home/botones-section")
);
const PlanesSection = lazy(() =>
  import("../../components/pages/home/planes-section")
);
const NovedadesSection = lazy(() =>
  import("../../components/pages/home/novedades-section")
);
const TeleconsultasSection = lazy(() =>
  import("../../components/pages/home/teleconsultas-section")
);
const CanalesAtencionSection = lazy(() =>
  import("../../components/pages/home/canales-atencion-section")
);
const CredencialSection = lazy(() =>
  import("../../components/pages/home/credencial-section")
);
const LogosComponent = lazy(() => import("../../components/pages/home/logos"));

const HomePage = () => {
  const { showLoaderHomePage, setShowLoaderHomePage } = useContext(
    GlobalContextMemorySpace
  );

  const [renderLoad, setRenderLoad] = useState(true);

  useEffect(() => {
    //Mount:

    setTimeout(() => {
      setRenderLoad(false);
    }, 700);

    return () => {
      //Unmount
    };
  }, []);

  return (
    <>
      {showLoaderHomePage || renderLoad ? (
        <CommonLoader></CommonLoader>
      ) : (
        <>
          <Suspense fallback={<CommonLoader />}>
            <PopUpComponent  />
            <HeroSection />
            <BotonesSection />
            <WhatsappFloatComponent />
            <PlanesSection />
            <NovedadesSection />
            <div style={{ marginTop: "40px" }}>
              <TeleconsultasSection />
            </div>
            {/* <CanalesAtencionSection /> */}
            <CredencialSection />
            <LogosComponent />
          </Suspense>
        </>
      )}
    </>
  );
};

export default HomePage;
