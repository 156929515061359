import TipoBaja from "../../data/tipoBaja.json";
import MotivoBaja from "../../data/motivoBaja.json";
import Desregula from "../../data/desregula.json";

import "./baja-plan.scss";

import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import Swal from "sweetalert2";
import React from "react";

import { PoliticaProteccionComponent } from "../../components/footer/politica-proteccion";
import { validateModel } from "./validationSchema";
import { useToggle } from "../../hooks/useToggle";

import { fetch } from "../../utils/fetch";
import { Helmet } from "react-helmet";

let emailsData;
process.env.REACT_APP_ENVIROMENT_DEBUG === "true"
  ? import("../../data/emails/emails.develop.json").then((module) => {
      emailsData = module.default;
    })
  : import("../../data/emails/emails.prod.json").then((module) => {
      emailsData = module.default;
    });

const BajaPlanPage = () => {
  const captcha = React.useRef(null);
  const politicaToggle = useToggle();
  const [loading, setLoading] = React.useState(false);

  const initialValues = {
    nombre: "",
    apellido: "",
    email: "",
    celular_prefix: "",
    celular: "",
    motivo: "",
    mensaje: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: "",
    credencial: "",
    cant_integrantes: "",
    desregula: "",
    motivo_baja: "",
    tipo_baja: "",
  };

  const onSubmit = async (values, resetForm) => {
    setLoading(true);
    if (captcha.current?.getValue()) {
      const correo = {
        to: emailsData.baja,
        subject: "Baja del plan de salud",
        text: `<b>Nombre</b> ${values.nombre} <br/>
        <b>Apellido</b> ${values.apellido} <br/>
        <b>Email</b> ${values.email} <br/>
        <b>Celular</b> (${values.celular_prefix})${values.celular} <br/>       
        <b>Número de credencial</b> ${values.credencial} <br/>        
        <b>Cantidad de integrantes</b> ${values.cant_integrantes} <br/>        
        <b>¿Desregula?</b> ${values.desregula} <br/>        
        <b>Motivo de baja</b> ${values.motivo_baja} <br/>        
        <b>Tipo de baja</b> ${values.tipo_baja} <br/>        
        <b>Mensaje</b> ${values.mensaje} <br/>        
        `,
      };
      try {
        for (const email of emailsData.baja) {
          correo.to = email;
          await fetch(`email`, "post", correo);
        }
      } catch (exception) {}

      try {
        correo.to = values.email;
        await fetch(`email`, "post", correo);
      } catch (exception) {}

      try {
        const repentanceUnsuscribe = {
          FirstName: values.nombre,
          LastName: values.apellido,
          Email: values.email,
          Phone: values.celular_prefix + " " + values.celular,
          Credential: values.credencial,
          Members: values.cant_integrantes,
          Deregulate: values.desregula,
          DownReason: values.motivo_baja,
          DownType: values.tipo_baja,
          Comment: values.mensaje,
          ReasonType: "Debaja",
        };
        await fetch(`repentance-unsubscribes`, "post", repentanceUnsuscribe);
      } catch (exception) {}

      console.log("reset");
      resetForm();
      captcha.current?.reset();
      setLoading(false);
      Swal.fire("Info!", "Solicitud de contacto enviada con éxito", "info");
    } else {
      setLoading(false);
      Swal.fire("Info!", "Debe indicar que no es un robot", "info");
    }
  };

  const handleChangeValue = (e, setFieldValue, handleChange, limit) => {
    const { name, value } = e.target;
    if (value.length > limit) {
      const truncatedValue = value.slice(0, limit);

      setFieldValue(name, truncatedValue);
    } else {
      handleChange(e);
    }
  };

  return (
    <section
      className="section_contacto"
      style={{ minHeight: "calc(100vh - 25px - 100px - 200px)" }}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="container-fluid">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-7 p-5">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validateModel}
              onSubmit={(values, { resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form className="section_contacto_form">
                  <h2 className="subtitle-global">
                    <b>Baja de plan de salud</b>
                    <hr className="hr6" />
                  </h2>
                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Nombre titular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text="
                        name="nombre"
                        value={values.nombre}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 25)
                        }
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.nombre}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Apellido titular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text="
                        name="apellido"
                        value={values.apellido}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 25)
                        }
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.apellido}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Email <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text="
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.email}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Celular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-3">
                      <input
                        type="text="
                        name="celular_prefix"
                        value={values.celular_prefix}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 4)
                        }
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">
                        {errors?.celular_prefix}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text="
                        name="celular"
                        value={values.celular}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 8)
                        }
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.celular}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Credencial titular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text="
                        name="credencial"
                        value={values.credencial}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 45)
                        }
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.credencial}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Cant. integrantes <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-3">
                      <input
                        type="text="
                        name="cant_integrantes"
                        value={values.cant_integrantes}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 3)
                        }
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">
                        {errors?.cant_integrantes}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        ¿Desregula? <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name="desregula"
                        value={values.desregula}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione una opción</option>
                        {Desregula.sort().map((value, index) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">{errors?.desregula}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Motivo de baja <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name="motivo_baja"
                        value={values.motivo_baja}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione una opción</option>
                        {MotivoBaja.sort().map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">{errors?.motivo_baja}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label>
                        Tipo de baja <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name="tipo_baja"
                        onChange={handleChange}
                        value={values.tipo_baja}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione un tipo de usuario</option>
                        {TipoBaja.sort().map((value, index) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">{errors?.tipo_baja}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-12 red-asterisk">
                      <label className="mb-2">
                        Mensaje <span>*</span>
                      </label>
                      <textarea
                        name="mensaje"
                        value={values.mensaje}
                        onChange={(e) =>
                          handleChangeValue(e, setFieldValue, handleChange, 500)
                        }
                        className="form-control b-radius-20 shadow-blue"
                        rows="4"
                      ></textarea>
                      <div className="text-danger">{errors?.mensaje}</div>
                    </div>
                  </div>

                  <div className="mb-4 form-check">
                    <input
                      name="aceptoPrivacidad"
                      value={values.aceptoPrivacidad}
                      checked={values.aceptoPrivacidad}
                      onChange={handleChange}
                      className="form-check-input"
                      type="checkbox"
                      id="defaultCheck1"
                    />
                    <label class="form-check-label" for="defaultCheck1">
                      Acepto la{" "}
                      <a
                        href="javascript:void(0)"
                        onClick={politicaToggle.onToggle}
                      >
                        {" "}
                        política de privacidad
                      </a>{" "}
                      del Hospital Británico
                    </label>
                    <PoliticaProteccionComponent
                      visible={politicaToggle.toggle}
                      onToggle={politicaToggle.onToggle}
                    />
                    <div className="text-danger">
                      {errors?.validateAceptoPrivacidad}
                    </div>
                  </div>
                  <div classname="mb-4 captcha">
                    <div className="row">
                      <div className="col-lg-12">
                        <div id="botCaptcha">
                          <ReCAPTCHA
                            ref={captcha}
                            sitekey={process.env.REACT_APP_CAPTCHA}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn2-color3-r"
                      disabled={loading}
                    >
                      {loading ? "Enviando..." : "Enviar"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BajaPlanPage;
