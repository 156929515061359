import React, { useState } from "react";
import "./libro-sugerencias.scss";
import contacto from "../../assets/img/bg-dejanos-tu-consulta.png";
import { fetch } from "../../utils/fetch";
import { useToggle } from "../../hooks/useToggle";
import { validateModel } from "./validationSchema";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { PoliticaProteccionComponent } from "../../components/footer/politica-proteccion";
import Razones from "../../data/razones.json";
import Areas from "../../data/areas.json";
import TiposUsuario from "../../data/tiposUsuario.json";

let emailsData;
process.env.REACT_APP_ENVIROMENT_DEBUG === 'true'?import('../../data/emails/emails.develop.json').then(module => {emailsData = module.default}):import('../../data/emails/emails.prod.json').then(module => {emailsData = module.default})


const LibroDeSugerenciasPage = () => {
  const captcha = React.useRef(null);
  const politicaToggle = useToggle();
  // const [prefixCel, setPrefixCel] = useState("");
  // const [cel, setCel] = useState("");

  const initialValues = {
    nombre: "",
    apellido: "",
    email: "",
    celular_prefix: "",
    celular: "",
    tienePlanDeSaludSi: false,
    tienePlanDeSaludNo: false,
    motivo: "",
    mensaje: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: "",
  };

  const onSubmit = async (values, resetForm) => {

    if (captcha.current?.getValue()) {
      const correo = {
        to: emailsData.sugerencias,
        subject: "Libro de sugerencias",
        text: `<b>Nombre</b> ${values.nombre} <br/>
        <b>Apellido</b> ${values.apellido} <br/>
        <b>Email</b> ${values.email} <br/>
        <b>Celular</b> (${values.celular_prefix})${values.celular} <br/>       
        <b>Razón</b> ${values.razon} <br/>        
        <b>Área</b> ${values.area} <br/>        
        <b>Usted es</b> ${values.tipoUsuario} <br/>        
        <b>Mensaje</b> ${values.mensaje} <br/>        
        `,
      };
      //<b>Opción</b> ${values.opcion} <br/>
      const { data } = await fetch(`email`, "post", correo);
      resetForm();
      captcha.current?.reset();
      Swal.fire("Info!", "Solicitud de contacto enviada con éxito", "info");
    } else {
      Swal.fire("Info!", "Debe indicar que no es un robot", "info");
    }
  };

  const handleChangeCel = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyNumbers = /^[0-9]+$/; // Expresión regular para validar números
    if (value === "" || onlyNumbers.test(value)) {
      setFieldValue(e.target.name, value);
    }
  };

  const handleChangeNombre = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyLettersAndSpaces = /^[A-Za-z\s]+$/; // Expresión regular para validar letras y espacios
    if (value === "" || onlyLettersAndSpaces.test(value)) {
      setFieldValue(e.target.name, value);
    }
  };

  return (
    <section
      className="section_contacto"
      style={{ minHeight: "calc(100vh - 25px - 100px - 200px)" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-5">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validateModel}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="section_contacto_form">
                  <h2 className="subtitle-global">
                    <b>Libro de sugerencias</b>
                    <hr className="hr6" />
                  </h2>
                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Nombre <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="nombre"
                        maxLength={25}
                        value={values.nombre}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeNombre(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.nombre}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Apellido <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="apellido"
                        maxLength={25}
                        value={values.apellido}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeNombre(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.apellido}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Email <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.email}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Celular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-3">
                      <input
                        type="text"
                        name="celular_prefix"
                        maxLength={4}
                        // onChange={handleChange}
                        value={values.celular_prefix}
                        onChange={(e) => handleChangeCel(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">
                        {errors?.celular_prefix}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        name="celular"
                        maxLength={8}
                        value={values.celular}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeCel(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.celular}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Razón <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <select
                        name="razon"
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione una razón</option>
                        {Razones.sort().map((value, index) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">{errors?.razon}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Área <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <select
                        name="area"
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione un área</option>
                        {Areas.sort().map((value, index) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">{errors?.area}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="red-asterisk">
                        Usted es <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <select
                        name="tipoUsuario"
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione un tipo de usuario</option>
                        {TiposUsuario.sort().map((value, index) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">{errors?.tipoUsuario}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-12">
                      <label className="mb-2 red-asterisk">
                        Mensaje <span>*</span>
                      </label>
                      <textarea
                        name="mensaje"
                        value={values.mensaje}
                        maxLength={500}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                        rows="4"
                      ></textarea>
                      <div className="text-danger">{errors?.mensaje}</div>
                    </div>
                  </div>

                  <div className="mb-4 form-check">
                    <input
                      name="aceptoPrivacidad"
                      value={values.aceptoPrivacidad}
                      onChange={handleChange}
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="defaultCheck1"
                    />
                    <label
                      class="form-check-label red-asterisk"
                      for="defaultCheck1"
                    >
                      Acepto la{" "}
                      <a
                        href="javascript:void(0)"
                        onClick={politicaToggle.onToggle}
                      >
                        {" "}
                        política de privacidad
                      </a>{" "}
                      del Hospital Británico <span>*</span>
                    </label>
                    <PoliticaProteccionComponent
                      visible={politicaToggle.toggle}
                      onToggle={politicaToggle.onToggle}
                    />
                    <div className="text-danger">
                      {errors?.validateAceptoPrivacidad}
                    </div>
                  </div>
                  <div classname="mb-4 captcha">
                    <div className="row">
                      <div className="col-lg-12">
                        <div id="botCaptcha">
                          <ReCAPTCHA
                            ref={captcha}
                            sitekey={process.env.REACT_APP_CAPTCHA}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn2-color3-r"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="col">
            <img src={contacto} className="img-contacto" alt="bg-contacto" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default LibroDeSugerenciasPage;
