import React from 'react';
import './template4.scss'
import dompurify from 'dompurify';

const Template4Component = ({ children }) => {
    // console.log('[Template 4]', children)
    const sanitizer = dompurify.sanitize;

    const sectionBgStyle = {
        backgroundImage: `url(${(children.template4.fondo.url)})`,
        backgroundSize: 'cover',
        height: '55vh',
        backgroundPosition:"center"
    }

    return (
        <>
            <section style={sectionBgStyle}>
            </section>
            <section>
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-lg-12">
                            <h2 className="global_title bold">
                                {children.template4.titulo}
                            </h2>
                            <hr className="hr6" />
                            <div className="contenido" style={{textAlign:"justify"}}>
                                <div dangerouslySetInnerHTML={{ __html: sanitizer(children.template4.contenido,{ADD_ATTR: ['target']}) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Template4Component;