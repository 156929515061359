/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import dompurify from "dompurify";
import { useParams, Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { fetchExternal } from "../../../utils/fetch";
import LocationIcon from "../../../assets/img/icon-location-green.png";
import CheckIcon from "../../../assets/img/check-green.png";
import Phone from "../../../assets/img/icon-phone-green.png";
import Tren from "../../../assets/img/tren-como-llego.png";
import Bus from "../../../assets/img/bus-como-llego.png";
import "./ver-hb-centros.scss";
import CommonLoader from "../../../components/common-loader";
import DynamicCommonLoader from "../../../components/common-loader/dynamicCommonLoader";

function VerHbRedCentrosPage() {
  const { id } = useParams();
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [centrosMedicos, setCentrosMedicos] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const params = useParams();

  const getData = async () => {
    try {
      const { data } = await fetchExternal(
        `centros-medicos/${id}?_locale=es&_publicationState=preview&eliminado=false`,
        "GET"
      );
      // console.log(data);
      setContenido(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const getCentrosMedicos = async () => {
    try {
      const { data } = await fetchExternal(
        "centros-medicos?_locale=es&eliminado=false&_sort=orden:ASC",
        "GET"
      );
      // console.log(data);
      setCentrosMedicos(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  React.useEffect(() => {
    setLoader(true);
    if (id) {
      getData();
      getCentrosMedicos();
    }
  }, [id]);

  function RenderAccordion({ items }) {
    return (
      <Accordion>
        {items &&
          items.map((data, index) => (
            <>
              {data.mostrar && (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header className="header-accordion">
                    {data.nombre}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row" id="accordion-blanco">
                      {data.items_acordion.map((itemsAcordion, index) => (
                        <div key={index} className="col-lg-4">
                          <img
                            src={CheckIcon}
                            alt="check-icon"
                            className="me-2"
                          />
                          {itemsAcordion.nombre}
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </>
          ))}
        <Accordion.Item
          key={"-1"}
          eventKey={"-1"}
          style={{ backgroundColor: "#99CA3E" }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              padding: "1rem 1.25rem",
              color: "#fff",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            <div className="d-none d-md-block" id="greenAccordion">
              Solicitá un nuevo turno, desde nuestro portal
            </div>
            <div className="d-block d-sm-none" id="greenAccordion">
              Solicitá tu turno
            </div>

            <div>
              <button
                type="button"
                className="btngestiones"
                style={{ backgroundColor: "#fff", color: "#294f90" }}
              >
                <span className="color"></span>
                <a
                  href="https://gestionesplandesalud.hbritanicoweb.com.ar"
                  target="_blank"
                  className="gestiones"
                >
                  GESTIONES
                  <br /> ONLINE
                </a>
              </button>
            </div>
          </div>
        </Accordion.Item>
      </Accordion>
    );
  }

  const [ubicacion, setUbicacion] = React.useState(true);
  const toggler = () => {
    ubicacion ? setUbicacion(false) : setUbicacion(true);
  };

  return (
    <section
      className="bg-gray ver-hb-centros"
      style={{ minHeight: "calc(100vh - 25px - 100px - 225px)" }}
    >
      <div className="hb-red-buttons-desktop">
        <div className="container-right-buttonss d-flex flex-column">
          {centrosMedicos &&
            centrosMedicos.map((data, index) => (
              <Link
                to={`/hb-red-centros/ver/${data.id}`}
                type="button"
                key={index}
                className="btn2-lateralr-color2"
              >
                {data.nombre}
              </Link>
            ))}
        </div>
      </div>

      <div className="container-right-buttonss hb-red-buttons-mobile">
        {centrosMedicos &&
          centrosMedicos.map((data, index) => (
            <Link
              to={`/hb-red-centros/ver/${data.id}`}
              type="button"
              key={index}
              className={
                params.id === data.id.toString()
                  ? "btn2-lateralr-color5"
                  : "btn2-lateralr-color2"
              }
            >
              {data.nombre}
            </Link>
          ))}
      </div>

      <div className="container">
        <div className="row pt-5 pb-5">
          {/* <div className="col-lg-3">
                        <h2 className="como_llegar__titulo mb-5">Centros Médicos</h2>
                        <div className="d-flex flex-column">
                            {centrosMedicos && centrosMedicos.map((data, index) => (
                                <Link to={`/centros-medicos/ver/${data.id}`} type="button" key={index} className="btn grandiend-btn-como-llegar my-1 b-radius-20 text-left">{data.nombre}</Link>
                            ))}
                        </div>
                    </div> */}
          {loader ? (
            <DynamicCommonLoader dynamic={"500px"} />
          ) : (
            <>
              <div className="col-lg-9 detalle-centro-medico">
                {contenido && (
                  <>
                    <h2 className="global_subtitle bold">
                      {contenido.id === 3
                        ? contenido.nombre
                        : "Centro Médico " + contenido.nombre}
                    </h2>
                    <hr className="hr6" />
                    <div className="card b-radius-20" id="card-ver">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6 text-center">
                            <img
                              src={contenido?.imagen?.url}
                              className="img-fluid b-radius-20"
                              alt="..."
                            />
                          </div>
                          <div className="col-lg-6 detalle-centro-medico">
                            <div className="card card-ver-centros-medicos bg-transparent border-0">
                              <div className="card-bod">
                                {/* <h5 className="card-title">
                                  {contenido.nombre}
                                </h5> */}

                                <p className="card-text row">
                                  <div className="col-1">
                                    <img src={LocationIcon} alt="location" />
                                  </div>
                                  <div className="col-11">
                                    <b>{contenido.direccion}</b>
                                    <br />
                                    {contenido.localidad}
                                  </div>
                                </p>
                                <p className="card-text row">
                                  <div className="col-1">
                                    <img src={Phone} alt="phone" />
                                  </div>
                                  <div className="col-11">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: sanitizer(
                                          contenido.info_contacto,
                                          { ADD_ATTR: ["target"] }
                                        ),
                                      }}
                                    />
                                  </div>
                                </p>
                              </div>
                              <div className="d-flex justify-content-end bg-transparent border-0">
                                <button
                                  type="button"
                                  onClick={toggler}
                                  className="btn2-color3-r"
                                  style={{
                                    padding: "0px 20px 0px 20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Cómo Llego
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {ubicacion ? (
                          <></>
                        ) : (
                          <div className="row">
                            <div className="col-lg-6 text-center">
                              <div className="map-container">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: contenido.iframe_mapa,
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 detalle-centro-medico">
                              <div className="card card-ver-centros-medicos bg-transparent border-0">
                                <div className="card-bod">
                                  <p className="card-text row">
                                    <div className="col-1">
                                      <img src={Bus} alt="bus" />
                                    </div>
                                    <div className="col-11">
                                      <b>Colectivos:</b>
                                      <br />
                                      <p>{contenido.colectivos}</p>
                                    </div>
                                  </p>
                                  <p className="card-text row">
                                    <div className="col-1">
                                      <img src={Tren} alt="tren" />
                                    </div>
                                    <div className="col-11">
                                      <b>Tren:</b>
                                      <p>{contenido.tren}</p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="row px-3">
                                                <hr className="separador_reusable_components my-3" />
                                            </div> */}
                        <hr className="hr6-sm" />
                        <p style={{ paddingLeft: "30px" }}>
                          <strong>
                            ¡Nos renovamos en un Nuevo Espacio más amplio y
                            seguro!
                          </strong>
                        </p>

                        <div className="row">
                          <div className="col-lg-12 detalle-centro-medico">
                            <RenderAccordion items={contenido.acordion} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
export default VerHbRedCentrosPage;
