import React from 'react';
import './informacion-util.scss'
import telutil1 from '../../assets/img/informacion-util/telutil1.png'
import telutil2 from '../../assets/img/informacion-util/telutil2.png'
import telutil3 from '../../assets/img/informacion-util/telutil3.png'
import dompurify from "dompurify";
import { fetch } from "../../utils/fetch";
import DynamicCommonLoader from '../../components/common-loader/dynamicCommonLoader';
import Card from 'react-bootstrap/Card';

const InformacionUtilPage = () => {


  const [loader, setLoader] = React.useState(true)
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await fetch(`informacion-utils?_locale=es`, "GET");
      // console.log(data[0]);
      setContenido(data[0]);
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  };

return(
  <>
    {loader 
      ? 
      
        <DynamicCommonLoader dynamic={"225px"}/>
      
      : 
      
        <>
        {contenido && 
        <>
        <section className='desktop'>
        <div className="container" >
          <div className="row mt-5">
            <div className="col-lg-12">            
              <h3 className="global_subtitle bold">{contenido.nombre}</h3>
              <hr className="hr6" />
              <p>{contenido.descripcion}</p>
            </div>
          </div>
          <div className="col-lg-12 fondo">
            <div className="row">
              <div className="col-lg-3">
                <img className="borde img-informacion" src={telutil1} width="250px" />
              </div>
              <div className="col-lg-9">
                <div className="row h-100 d-flex align-items-center">                
                  
                  <div className="col-lg-6 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.whatsapp,{ADD_ATTR: ['target']})}} />
                  </div>
                  <div className="col-lg-6 h-100 d-flex align-items-center justify-content-center">
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.centro_atencion_socio,{ADD_ATTR: ['target']})}} />
                  </div>
                </div>
              </div>
            </div>
    
          </div>
    
          <div className="col-lg-12 fondo">
    
            <div className="row">
              <div className="col-lg-3">
                <img className="borde img-informacion" src={telutil2} width="250px" />
              </div>
              <div className="col-lg-9 ">
                <div className="row h-100 d-flex align-items-center">                 
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.urgencias_emergencias,{ADD_ATTR: ['target']})}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.turnos_odontologia)}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center">
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.asistencia_al_viajero,{ADD_ATTR: ['target']})}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 fondo">
            <div className="row">
              <div className="col-lg-3">
                <img className="borde img-informacion" src={telutil3} width="250px" />
              </div>
              <div className="col-lg-9 ">
                <div className="row h-100 d-flex align-items-center">                 
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.central_de_turnos,{ADD_ATTR: ['target']})}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.turnos_diagnostico_x_imagenes,{ADD_ATTR: ['target']})}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center">
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido.teleconsulta,{ADD_ATTR: ['target']})}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        

      <section className='mobile'>
      <div className="container" >
          <div className="row mt-5">
            <div className="col-lg-12">            
              <h2 className="global_subtitle bold">{contenido.nombre}</h2>
              <div className="hr6" />

              <p>{contenido?.descripcion}</p>
            </div>
          </div>
          </div>
          <div className='card-wrapper'>
             <Card style={{ width: '18rem' }}>      
                <Card.Body style={{paddingTop:'20px'}}>
                  <Card.Text>
                  <div className="innerHTML" style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: sanitizer(contenido?.whatsapp,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" id="centro-atencion" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.centro_atencion_socio,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem',  }}>      
                <Card.Body style={{paddingTop:'3.8rem'}}>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.urgencias_emergencias,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML"  id="odontologia" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.turnos_odontologia,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.asistencia_al_viajero,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.central_de_turnos,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.turnos_diagnostico_x_imagenes,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.teleconsulta,{ADD_ATTR: ['target']})}} />
                  </Card.Text>
                </Card.Body>
              </Card>
    </div>
      </section>
      
      </>
        }
        </>

    
    }
  </>
    

);
}
export default InformacionUtilPage;