import React from 'react';
import './debito-en-cuenta.scss';
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from 'formik';
import { validateModel } from './validationSchema';
import { useToggle } from '../../hooks/useToggle';
import Swal from 'sweetalert2';
import { PoliticaProteccionComponent } from '../../components/footer/politica-proteccion';
import { fetch } from '../../utils/fetch';

const DebitoEnCuentaPage = () => {

  const [credito, setCredito] = React.useState(true)

  const captcha = React.useRef(null)
  const politicaToggle = useToggle();  


  const initialValues = {
    nombre: "",
    apellido: "",
    email: "",
    celular_prefix: "",
    celular: "",
    dni:"",
    no_socio:"",
    direccion:"",
    numero:"",
    localidad:"",
    cp:"",
    mensaje: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: '',
    
  };

  const onSubmit = async (values, resetForm) => {

    if (captcha.current?.getValue()) {

      const correo = {
        to: process.env.REACT_APP_MAIL_FORMAS_PAGO,
        subject: "Débito en cuenta",
        text: `<b>Nombre</b> ${values.nombre} <br/>
        <b>Apellido</b> ${values.apellido} <br/>
        <b>Email</b> ${values.email} <br/>
        <b>Número de socio</b> ${values.no_socio} <br/>
        <b>Celular</b> (${values.celular_prefix})${values.celular} <br/>       
        <b>Dirección</b> ${values.direccion} ${values.numero} <br/>        
        <b>Localidad</b> ${values.area} <br/>        
        <b>Código postal</b> ${values.tipoUsuario} <br/>        
        <b>Mensaje</b> ${values.mensaje} <br/>        
        `
      }
      //<b>Opción</b> ${values.opcion} <br/>
      const { data } = await fetch(`email`, 'post', correo);
      resetForm()
      captcha.current?.reset();
      Swal.fire('Info!', "Solicitud enviada con éxito", 'info')
    } else {
      Swal.fire('Info!', "Debe indicar que no es un robot", 'info')
    }
  }


return(  
  <section className="position-relative">      
      <div className="container-right-buttons cartilla-buttons-desktop">
      <button onClick={()=>setCredito(true)} type="button" className={credito ? "btn2-lateralr-color5": "btn2-lateralr-color2"}>Débito en cuenta</button>
      <button onClick={()=>setCredito(false)} type="button" className={credito ? "btn2-lateralr-color2": "btn2-lateralr-color5"}>Débito automático</button>
      </div>
{credito?
      <div className="container pt-5 pb-5">
          <div className="row">
              <div className="col-lg-9">
                  <h2 className="global_subtitle bold">
                      Débito en cuenta
                  </h2>
                  <hr className="hr6"/>
                  <div className="cartilla-buttons-mobile">
                  <button onClick={()=>setCredito(true)} type="button" className={credito ? "btn2-lateralr-color5": "btn2-lateralr-color2"}>Débito en cuenta</button>
                  <button onClick={()=>setCredito(false)} type="button" className={credito ? "btn2-lateralr-color2": "btn2-lateralr-color5"}>Débito automático</button>
                  </div>
                  <div className="contenido">
                  <p>
                  Para este trámite necesitará su número de CBU (clave bancaria única), que figura en el resumen de cuenta bancaria (22 dígitos) o, de lo contrario, podrá consultarlo directamente con su banco. Para adherirse deberá contactarse con nuestro Centro de Atención al Asociado (0810-999-7742) de lunes a viernes de 8 a 17hs, personalmente en nuestras oficinas o completando este formulario:
                  </p>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-lg-9">
              <div className="card b-radius-20 p-5" id='card-credito'>
              <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validateModel}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (                
              <form className="section_contacto_form">                              
                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Nombre*</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text=" name="nombre" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.nombre}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Apellido*</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="apellido" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.apellido}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Email</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="email" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.email}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Dni</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="dni" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.dni}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Nro Socio</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="no_socio" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.no_socio}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Celular</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="text=" name="celular_prefix" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.celular_prefix}</div>
                          </div>
                          <div className="col-lg-7">
                            <input type="text" name="celular" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.celular}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Dirección</label>
                          </div>
                          <div className="col-lg-6">
                            <input type="text" name="direccion" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.direccion}</div>
                          </div>
                          <div className="col-lg-1">
                          <label className="categoria-texto2">Nº</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="text" name="numero" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.numero}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Localidad</label>
                          </div>
                          <div className="col-lg-6">
                            <input type="text" name="localidad" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.localidad}</div>
                          </div>
                          <div className="col-lg-1">
                          <label className="categoria-texto2">CP</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="text=" name="cp" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.cp}</div>
                          </div>
                      </div>
                      
                      <div className="mb-4 row align-items-center">
                      <div className="col-lg-12">                      
                          <textarea name="mensaje" onChange={handleChange} className="form-control b-radius-20 shadow-blue" rows="4" placeholder="Comentario"></textarea>
                          <div className="text-danger">{errors?.mensaje}</div>
                      </div>
                      </div>

                      <div className="mb-4 form-check">
                    <input name='aceptoPrivacidad' value={values.aceptoPrivacidad} onChange={handleChange} className="form-check-input" type="checkbox" defaultValue id="defaultCheck1" />
                    <label class="form-check-label" for="defaultCheck1">
                      Acepto la <a href='javascript:void(0)' onClick={politicaToggle.onToggle}> política de privacidad</a> del Hospital Británico
                    </label>
                    <PoliticaProteccionComponent
                                visible={politicaToggle.toggle}
                                onToggle={politicaToggle.onToggle} />
                    <div className="text-danger">{errors?.validateAceptoPrivacidad}</div>
                    
                    
                  </div>
                  <div classname="mb-4 captcha">
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div id='botCaptcha'>
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA}                          
                        />                        

                      </div>
                    </div>
                  </div>
                  </div>

                      <div className="">
                        <button type="button" onClick={() => handleSubmit()} className="btn2-color3-r" id='boton-'>Enviar</button>
                      </div>
                  </form>
                  )}
                  </Formik>
              </div>
              </div>
          </div>

          <div className="row">
              <div className="col-lg-12">
                  <hr className="hr6"/>
              </div>
          </div>
      </div>
  :
  <div className="container pt-5 pb-5">
          <div className="row">
              <div className="col-lg-9">
                  <h2 className="global_subtitle bold">
                      Débito automático
                  </h2>
                  <hr className="hr6"/>
                  <div className="cartilla-buttons-mobile">
                  <button onClick={()=>setCredito(true)} type="button" className={credito ? "btn2-lateralr-color5": "btn2-lateralr-color2"}>Débito en cuenta</button>
                  <button onClick={()=>setCredito(false)} type="button" className={credito ? "btn2-lateralr-color2": "btn2-lateralr-color5"}>Débito automático</button>
                  </div>
                  <div className="contenido">
                  <p>
                  Si usted posee una tarjeta de crédito Visa, Mastercard y American Express, puede adherirse al débito automático comunicándose a nuestro Centro de Atención al Asociado (0810-999-7742) de lunes a viernes de 8 a 17hs, personalmente en nuestras oficinas o completando este formulario.
                  </p>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-lg-9">
              <div className="card b-radius-20 p-5" id='card-credito'>
              <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validateModel}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
              <form className="section_contacto_form">
                
                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Nombre*</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text=" name="nombre" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.nombre}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Apellido*</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="apellido" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.apellido}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Email</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="email" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.email}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Dni</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="dni" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.dni}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">No Socio</label>
                          </div>
                          <div className="col-lg-10">
                            <input type="text" name="no_socio" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.no_socio}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Celular</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="text=" name="celular_prefix" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.celular_prefix}</div>
                          </div>
                          <div className="col-lg-7">
                            <input type="text" name="celular" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.celular}</div>
                          </div>
                      </div>


                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Dirección</label>
                          </div>
                          <div className="col-lg-6">
                            <input type="text" name="direccion" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.direccion}</div>
                          </div>
                          <div className="col-lg-1">
                          <label className="categoria-texto2">Nº</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="text" name="numero" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.numero}</div>
                          </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                          <div className="col-lg-2">
                          <label className="categoria-texto2">Localidad</label>
                          </div>
                          <div className="col-lg-6">
                            <input type="text" name="localidad" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.localidad}</div>
                          </div>
                          <div className="col-lg-1">
                          <label className="categoria-texto2">CP</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="text=" name="cp" onChange={handleChange} className="form-control b-radius-20 shadow-blue" />
                            <div className="text-danger">{errors?.cp}</div>
                          </div>
                      </div>
                      <div className="mb-4 form-check">
                    <input name='aceptoPrivacidad' value={values.aceptoPrivacidad} onChange={handleChange} className="form-check-input" type="checkbox" defaultValue id="defaultCheck1" />
                    <label class="form-check-label" for="defaultCheck1">
                      Acepto la <a href='javascript:void(0)' onClick={politicaToggle.onToggle}> política de privacidad</a> del Hospital Británico
                    </label>
                    <PoliticaProteccionComponent
                                visible={politicaToggle.toggle}
                                onToggle={politicaToggle.onToggle} />
                    <div className="text-danger">{errors?.validateAceptoPrivacidad}</div>
                    
                    
                  </div>
                  <div classname="mb-4 captcha">
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div id='botCaptcha'>
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA}                          
                        />                        

                      </div>
                    </div>
                  </div>
                  </div>

                      <div className="mb-4 row align-items-center">
                      <div className="col-lg-12">                      
                          <textarea name="mensaje" onChange={handleChange} className="form-control b-radius-20 shadow-blue" rows="4" placeholder="Comentario"></textarea>
                          <div className="text-danger">{errors?.mensaje}</div>
                      </div>
                      </div>

                      <div className="">
                        <button type="button" onClick={() => handleSubmit()} className="btn2-color3-r" id='boton-'>Enviar</button>
                      </div>
                  </form>
                  )}
                  </Formik>
              </div>
              </div>
          </div>

          <div className="row">
              <div className="col-lg-12">
                  <hr className="hr6"/>
              </div>
          </div>
      </div>
}
  
  
  </section>
);
}
export default DebitoEnCuentaPage;