/* eslint-disable react/jsx-filename-extension */
import React, { lazy, Suspense } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import Layout from "./layout/default";
import HomePage from "./pages/home";
import PlanesPage from "./pages/planes";
import NovedadesPage from "./pages/novedades";
import PrestadoresPage from "./pages/prestadores";
import VerNovedadesPage from "./pages/novedades/detalle";
import VerPrestadoresPage from "./pages/prestadores/detalle";
import TeleconsultasPage from "./pages/teleconsultas";
import DejanosTuConsultaPage from "./pages/contacto";
import AfiliarmePage from "./pages/afiliarme";
import AfiliarmePageIframe from "./pages/afiliarme/index-iframe";
import DebitoEnCuentaPage from "./pages/debito-en-cuenta";
import ExcelPage from "./components/pages/home/excel";
import CartilaPage from "./pages/cartilla";
import QuienesSomosPage from "./pages/quienes-somos";
import InformacionUtilPage from "./pages/informacion-util";
import PageTemplate from "./pages/templates";
import HbRedCentrosPage from "./pages/hb-red-centros";
import TemplatePlanesComponent from "./components/templates/template-planes";
import VerHbRedCentrosPage from "./pages/hb-red-centros/ver";
import PageTemplatePreview from "./pages/templates/page-template-preview";
import PlanesSection from "./components/pages/home/planes-section";
import { GlobalContext } from "./context/globalContext";
import CommonLoader from "./components/common-loader";
import LibroDeSugerenciasPage from "./pages/libro-sugerencias";
import BajaPlanPage from "./pages/baja-plan";
import TramiteArrepentimientoPage from "./pages/tramite-arrepentimiento";
import PageError from "./pages/page-error";
import ErrorBoundary from "./components/exception/error";
import PromocionesPage from "./pages/promociones";
import VerPromocionesPage from "./pages/promociones/detalle";
import VerPromocionesPagePreview from "./pages/promociones/detalle/promociones-preview";
import ReactGA from "react-ga4";

ReactGA.initialize("G-L5SY0BTMRH");
//const PageTemplate = lazy(() => import('./pages/templates'));

function App() {
  const location = useLocation();

  React.useEffect(() => {
    localStorage.clear();
  }, []);

  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  const mainRoutes = {
    path: "/",
    element: (
      <GlobalContext>
        <Layout />{" "}
      </GlobalContext>
    ),
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/:slug", element: <PageTemplate /> },
      { path: "/planes/:slug", element: <TemplatePlanesComponent /> },
      { path: "/novedades/:slug", element: <VerNovedadesPage /> },
      {
        path: "/planes-preview/:slug",
        element: <TemplatePlanesComponent />,
      } /*revisar element*/,
      { path: "/pages-preview/:slug", element: <PageTemplatePreview /> },
      { path: "/novedades-preview/:slug", element: <VerNovedadesPage /> },
      { path: "/novedades/", element: <NovedadesPage /> },
      { path: "/prestadores/", element: <PrestadoresPage /> },
      { path: "/prestadores/:slug", element: <VerPrestadoresPage /> },
      { path: "/prestadores-preview/:slug", element: <VerPrestadoresPage /> },
      { path: "/planes", element: <PlanesPage /> },
      { path: "/pages/teleconsulta", element: <TeleconsultasPage /> },
      { path: "/pages/afiliarme", element: <AfiliarmePageIframe /> },
      // { path: "/pages/afiliarme-old", element: <AfiliarmePage /> },
      { path: "/pages/quienes-somos", element: <QuienesSomosPage /> },
      {
        path: "/pages/tramite-arrepentimiento",
        element: <TramiteArrepentimientoPage />,
      },
      {
        path: "/pages/libro-de-sugerencias",
        element: <LibroDeSugerenciasPage />,
      },
      { path: "/pages/baja-plan-salud", element: <BajaPlanPage /> },
      { path: "/hb-red-centros", element: <HbRedCentrosPage /> },
      { path: "/hb-red-centros/ver/:id", element: <VerHbRedCentrosPage /> },
      { path: "/debito-en-cuenta", element: <DebitoEnCuentaPage /> },
      { path: "/cartilla", element: <CartilaPage /> },
      { path: "/informacion-util", element: <InformacionUtilPage /> },
      { path: "/dejanos-tu-consulta", element: <DejanosTuConsultaPage /> },
      { path: "/promociones/:idPlan", element: <PromocionesPage /> },
      {
        path: "/promociones-detalle/:id/:idPlan",
        element: <VerPromocionesPage />,
      },
      {
        path: "/promociones-preview/:slug",
        element: <VerPromocionesPagePreview />,
      },
      // { path: '/error', element: <PageError /> }
    ],
  };

  const routing = useRoutes([mainRoutes]);
  return routing;
}

export default App;
