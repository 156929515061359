/* eslint-disable eqeqeq */
import React from "react";
import { fetch } from "../../utils/fetch";
import "./promociones.scss";
import ArrowGreen from "../../assets/img/arrow_green.png";
import { Link,useParams } from "react-router-dom";
import CommonLoader from "../../components/common-loader";
import DynamicCommonLoader from "../../components/common-loader/dynamicCommonLoader";

const PromocionesPage = () => {
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  // const recorrido=["a","b","c","c","a","b","b","c","a"];
  const params = useParams();
  const { idPlan } = params;
  // const getData = async () => {
  //     const {data} = await fetch(`novedades?_locale=es`, 'GET' );

  //     setContenido(data)
  // }

  React.useEffect(() => {
    fetch(
      `planes/${idPlan}`,
      "GET"
    )
      .then((res) => {
        // console.log("PROMOCIONES",res.data.promociones)
        setContenido(res.data.promociones.filter(promo=>!promo.promocion.eliminado && promo.promocion.onOff) ?? []);
      })
      .catch((error) => {
        setLoader(false);
        /*renderizo un error generico*/
        // console.log(error)
      })
      .finally(() => {
        setLoader(false);
      });

    // getData()
  }, []);

  /*
agregar parametros ingresados en el CMS dentro del fetch

  /*
    -opc2
    lleva foto
    lleva titulo
    no lleva descripcion corta
    */
  function Opc2({ fila, columna, data }) {
    return (
      <div
        className={`card b-radius-10 p-2 card-novedades ${
          fila == columna && "bg-azul"
        } border-0 `}
      >      
        <div className="card-body">
          {/* Foto */}
          <div className="card-foto opc2">
            <img
              src={data?.imagen?.url}
              alt="novedad"
              className="b-radius-10"
            />
          </div>
          {/* Titulo */}
          <div className="card-title opc2">
            {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}             
             {/* {data.descripcionCorta.substr(0, 80)} */}
          </div>
        </div>
        <div className="card-footer opc2 d-flex bg-transparent border-0 justify-content-end">
          {fila == columna ? (
            <Link target="_blank" to={"/promociones-detalle/" + data.id+"/"+idPlan}>
              {" "}
              <img src={ArrowGreen} alt="arrow" />{" "}
            </Link>
          ) : (
            <Link target="_blank" to={"/promociones-detalle/" + data.id+"/"+idPlan}>
              {" "}
              <img src={ArrowGreen} alt="arrow" />{" "}
            </Link>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className="bg-novedades">
          <div className="container">
            <div className="row pt-5 pb-3">
              <div className="col-lg-12">
                <h2 className="global_title bold">Promociones vigentes</h2>
                <hr className="hr6 w-50" />
              </div>
            </div>
            <div className="row">
              {contenido &&
                contenido.map((data, index) => {
                  let fila = parseInt(index / 3) % 3;
                  let columna = index % 3;
                  // var opcion=recorrido[index%recorrido.length];

                  // console.log("INDEX: "+index+", FILA: "+fila+", COLUMNA:"+columna);
                  return (
                    <div className="col-lg-4 mb-4" key={index}>
                      {/* Validaciones Opc1  */}                      
                        <Opc2 data={data.promocion} fila={fila} columna={columna} />                     
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default PromocionesPage;
