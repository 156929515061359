import * as Yup from "yup";

// const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const phoneRegex = /^([0-9]{0,4})[-. ]?([0-9]{0,8})$/;
const DNIRegex = /^[0-9]*$/;

//DNI REGEX         /^\d{8}(?:[-\s]\d{4})?$/ ONLY DNI
//PASAPORTE REGEX
//LIBRETA REGEX
//CEDULA REGEX

export const validateModel = Yup.object().shape({
  nombre: Yup.string().required("Campo obligatorio"),
  apellido: Yup.string().required("Campo obligatorio"),
  // telefono: Yup.string()
  //   .matches(phoneRegex, "Ingrese un numero de telefono")
  //   .required("Campo obligatorio"),
  celular: Yup.number()
    .required("Campo obligatorio")
    .typeError("Ingrese un numero válido"),
  celular_prefix: Yup.number()
    .required("Campo obligatorio")
    .typeError("Ingrese un numero válido"),
  email: Yup.string().email("Email inválido").required("Campo obligatorio"),
  numeroDocumento: Yup.string().required("Campo obligatorio"),
  // .matches(DNIRegex, "Ingrese un documento valido")
  comentario: Yup.string().required("Campo obligatorio"),
});
