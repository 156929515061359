import React, { useEffect } from "react";
import "./tramite.scss";
// import contacto from "../../assets/img/bg-dejanos-tu-consulta.png";
import { Formik } from "formik";
import { validateModel } from "./validationSchema";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { useToggle } from "../../hooks/useToggle";
import { Button, Modal } from "react-bootstrap";
import { fetch } from "../../utils/fetch";

let emailsData;
process.env.REACT_APP_ENVIROMENT_DEBUG === 'true'?import('../../data/emails/emails.develop.json').then(module => {emailsData = module.default}):import('../../data/emails/emails.prod.json').then(module => {emailsData = module.default})

function ScrollTop() {
  window.scrollTo({ top: 0, left: 0, beahivor: "smooth" });
}
const TramiteArrepentimientoPage = () => {
  const captcha = React.useRef(null);
  const alerta = useToggle();
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    nombre: "",
    apellido: "",
    telefono: "",
    celular_prefix: "",
    celular: "",
    email: "",
    tipoDocumento: "DNI",
    numeroDocumento: "",
    comentario: "",
  };

  useEffect(() => {
    alerta.onToggle(true);
  }, []);

  const onSubmit = async (values, resetForm) => {
    setLoading(true);
    if (captcha.current?.getValue()) {
      const correo = {
        to: emailsData.arrepentimiento,
        subject: "Solicitud de trámite de arrepentimiento",
        text: `<b>Nombre</b> ${values.nombre} <br/>
        <b>Apellido</b> ${values.apellido} <br/>
        <b>Celular</b> (${values.celular_prefix})${values.celular} <br/>
        <b>Email</b> ${values.email} <br/>
        <b>Tipo de documento</b> ${values.tipoDocumento} <br/>
        <b>Comentario</b> ${values.comentario} <br/>        
        `,
      };

      try {
        const { responseEnterpriseEmail } = await fetch(
          `email`,
          "post",
          correo
        );
      } catch (exception) {}

      try {
        correo.to = values.email;
        const { responseUserEmail } = await fetch(`email`, "post", correo);
      } catch (exception) {}

      try {
        const repentanceUnsuscribe = {
          FirstName: values.nombre,
          LastName: values.apellido,
          Email: values.email,
          Phone: values.celular_prefix + " " + values.celular,
          IdentificationNumberType: values.tipoDocumento,
          IdentificationNumber: values.numeroDocumento,
          Comment: values.mensaje,
          ReasonType: "Arrepentimiento",
        };
        const { responseRegister } = await fetch(
          `repentance-unsubscribes`,
          "post",
          repentanceUnsuscribe
        );
      } catch (exception) {}

      resetForm();
      captcha.current?.reset();
      setLoading(false);
      Swal.fire("Info!", "Solicitud enviada con éxito", "info");
    } else {
      setLoading(false);
      Swal.fire("Info!", "Debe indicar que no es un robot", "info");
    }
  };

  const handleChangeCel = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyNumbers = /^[0-9]+$/; // Expresión regular para validar números
    if (value === "" || onlyNumbers.test(value)) {
      setFieldValue(e.target.name, value);
    }
  };

  const handleChangeNombre = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyLettersAndSpaces = /^[A-Za-z\s]+$/; // Expresión regular para validar letras y espacios
    if (value === "" || onlyLettersAndSpaces.test(value)) {
      setFieldValue(e.target.name, value);
    }
  };

  return (
    <section className="section_contacto">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 p-5">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validateModel}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="section_contacto_form" onsubmit={handleSubmit}>
                  <h2 className="subtitle-global">
                    <b>Trámite de arrepentimiento</b>
                  </h2>
                  <hr className="hr6" />
                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3 red-asterisk">
                      <label className="label">
                        Nombre <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="nombre"
                        maxLength={25}
                        value={values.nombre}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeNombre(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.nombre}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="red-asterisk">
                        Apellido <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="apellido"
                        maxLength={25}
                        value={values.apellido}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeNombre(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.apellido}</div>
                    </div>
                  </div>
                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="red-asterisk">
                        Celular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-3 col-4">
                      <input
                        type="text"
                        name="celular_prefix"
                        maxLength={4}
                        // onChange={handleChange}
                        value={values.celular_prefix}
                        onChange={(e) => handleChangeCel(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">
                        {errors?.celular_prefix}
                      </div>
                    </div>
                    <div className="col-lg-6 col-8">
                      <input
                        type="text"
                        name="celular"
                        maxLength={8}
                        value={values.celular}
                        // onChange={handleChange}
                        onChange={(e) => handleChangeCel(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.celular}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="red-asterisk">
                        Email <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">{errors?.email}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="red-asterisk">
                        Tipo documento <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name="tipoDocumento"
                        value={values.tipoDocumento}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option value={"DNI"} selected>
                          DNI
                        </option>
                        <option value={"Pasaporte"}>Pasaporte</option>
                        <option value={"Cedula"}>Cédula</option>
                        <option value={"Libreta"}>Libreta</option>
                      </select>
                      <div className="text-danger">{errors?.tipoDocumento}</div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="red-asterisk">
                        Nro Documento <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="numeroDocumento"
                        value={values.numeroDocumento}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      <div className="text-danger">
                        {errors?.numeroDocumento}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-12">
                      <label className="mb-3 red-asterisk">
                        Mensaje <span>*</span>
                      </label>
                      <textarea
                        name="comentario"
                        value={values.comentario}
                        maxLength={500}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                        rows="4"
                      ></textarea>
                      <div className="text-danger">{errors?.comentario}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div id="botCaptcha">
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn2-color3-r"
                      disabled={loading}
                    >
                      {loading ? "Enviando..." : "Enviar"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="col-lg-3"></div>

          {/* <div className="col">
            <img src={contacto} className="img-contacto" alt="bg-contacto" />
          </div> */}
        </div>
      </div>

      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        show={alerta.toggle}
        centered
        onHide={alerta.onToggle}
      >
        <Modal.Header closeButton className="header-proteccion">
          <Modal.Title className="titulo-proteccion">Importante</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="politica-proteccion">
            <div className="barra-politica-proteccion" />
            <p>
              La solicitud de arrepentimiento de alta de nuestro plan de salud,
              es un trámite solo válido para quien haya completado un legajo de
              afiliación dentro de los últimos 10 días.
              <br />
              <br />
              La cancelación de la solicitud de alta, implica que se
              interrumpirá el ingreso como socios de todos los integrantes del
              grupo familiar que hayan sido declarados en el formulario.
              <br />
              <br />
              En caso de haber registrado consumos, se cursará una baja del
              servicio a esa fecha y se emitirá una facturación por los días
              correspondientes.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn2-color3-r"
            id="boton-politicas"
            variant="primary"
            onClick={() => {
              alerta.onToggle();
              ScrollTop();
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};
export default TramiteArrepentimientoPage;
