import React from 'react';
import dompurify from 'dompurify';
import './template1.scss'
const Template1Component = ({children}) => {
//   console.log("[Template1]", children)
  const sanitizer = dompurify.sanitize; 
return(
  
  <section>
  <div className="container-fluid">
      <div className="row p-0">
          <div className="col-lg-12 p-0">
              {/* <img src={bg} alt="..." className="img-fluid" /> */}
          </div>
      </div>
  </div>
  <div className="container pb-5">
      <div className="row mt-5">
          <div className="col-lg-9">
              <h2 className="global_title bold">
                  {children.template1.titulo}
              </h2>
              <hr className="hr6" />
              <div className="contenido">
              <div dangerouslySetInnerHTML={{__html: sanitizer(children.template1.contenido,{ADD_ATTR: ['target']})}} />
              </div>
          </div>
          <div className="col-lg-3 img-template-1-container">
            <img src={children.template1.imagen.url} className="img-fluid img-template-1" />
          </div>
      </div>
  </div>
</section>
);
}
export default Template1Component;