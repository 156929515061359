import React, { useEffect } from "react";
import { mySortMenuItems } from "../utils/tools";
import { useState } from "react";

const useSortMenu = (lang, menuSource) => {
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    for (const item of menuSource) {
      if (item.submenu.length) {
        mySortMenuItems(item.submenu);
      }
    }

    setMenus(menuSource);
  }, [lang, menuSource]);

  return { menus };
};

export default useSortMenu;
