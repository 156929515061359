import "./cartilla.scss";
import React, { useRef } from "react";
import { csv2json } from "../../utils/csv_to_json";
import { fetch } from "../../utils/fetch";
import CommonLoader from "../../components/common-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

const CartilaPage = () => {
  const [especialidad, setEspecialidad] = React.useState(true);

  const [prestadores, setPrestadores] = React.useState(null);
  const [especialidades, setEspecialidades] = React.useState(null);
  const [zonas, setZonas] = React.useState(null);
  const [barrios, setBarrios] = React.useState(null);
  const [planes, setPlanes] = React.useState(null);
  const [cartilla, setCartilla] = React.useState(null);

  const [selectedPrestadores, setSelectedPrestadores] = React.useState();
  const [selectedEspecialidades, setSelectedEspecialidades] = React.useState();
  const [selectedZonas, setSelectedZonas] = React.useState();
  const [selectedBarrios, setSelectedBarrios] = React.useState();
  const [selectedPlanes, setSelectedPlanes] = React.useState();
  const [searchText, setSearchText] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [loader, setLoader] = React.useState(true);

  const refTable = useRef(null);

  const refTipoPrestador = useRef(null);
  const refEspecialidad = useRef(null);
  const refZona = useRef(null);
  const refBarrio = useRef(null);
  const refPlan = useRef(null);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const prestadores = fetch(`external-cartilla/tiposprestador`, "GET");
    const especialidades = fetch(`external-cartilla/especialidades`, "GET");
    const zonas = fetch(`external-cartilla/zonas`, "GET");
    const barrios = fetch(`external-cartilla/barrios`, "GET");
    const planes = fetch(`external-cartilla/planes`, "GET");

    Promise.all([prestadores, especialidades, zonas, barrios, planes]).then(response => {
      setPrestadores(csv2json(response[0].data.result));
      setEspecialidades(csv2json(response[1].data.result));
      setZonas(csv2json(response[2].data.result));
      setBarrios(csv2json(response[3].data.result));
      setPlanes(csv2json(response[4].data.result));
    }).finally(_=>{
      setLoader(false);
    })

  };

  // const getData = async () => {
  //   const { data: response } = await fetch(`external-cartilla/all`, "GET");
  //   // setPrestadores(csv2json(response.tiposPrestador.result));

  //   // setEspecialidades(csv2json(response.especialidades.result));
  //   // setZonas(csv2json(response.zonas.result));
  //   // setBarrios(csv2json(response.barrios.result));
  //   // setPlanes(csv2json(response.planes.result));
  //   setLoader(false);
  // };

  const normalizar = (nombre) => {
    return nombre
      .toLowerCase()
      .split(" ")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1, item.length))
      .join(" ");
  };

  const executeScroll = () => refTable.current.scrollIntoView(true);

  const handleChangePrestadores = async (e) => {
    const value = e.target.value;
    setSelectedPrestadores(value);
    setBarrios(null);
    // setZonas(null)
    // setPlanes(null)

    setSelectedPrestadores(null);
    setSelectedEspecialidades(null);
    setSelectedZonas(null);
    setSelectedBarrios(null);
    setSelectedPlanes(null);

    refEspecialidad.current.selectedIndex = 0;

    if (value === "Todas") {
      getData();
      return;
    }

    const { data } = await fetch(
      `external-cartilla/especialidades?tipoPrestador=${value}`,
      "GET"
    );

    setEspecialidades(csv2json(data.result));

    const response = await fetch(
      `external-cartilla/zonas?tipoPrestador=${value}`,
      "GET"
    );

    setZonas(csv2json(response.data.result));
  };

  const handleChangeEspecialidades = async (e) => {
    const value = e.target.value;

    // var payload={};

    // if(refTipoPrestador.current.value!==null && refTipoPrestador.current.value!=="Todas")
    // {
    //   payload.tipoPrestador=refTipoPrestador.current.value;
    // }

    // if(refEspecialidad.current.value!==null && refEspecialidad.current.value!=="Todas")
    // {
    //   payload.especialidad=refEspecialidad.current.value;
    // }

    // var queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');

    // // const { data } = await fetch(`external-cartilla/zonas?tipoPrestador=${refTipoPrestador.current.value}&especialidad=${refEspecialidad.current.value}`, "GET");
    // const { data } = await fetch(`external-cartilla/zonas?${queryString}`, "GET");
    // setZonas(csv2json(data.result));
    // setBarrios(null)
    // setPlanes(null)
    setSelectedEspecialidades(value);
  };

  const handleChangeZonas = async (e) => {
    const value = e.target.value;
    //setSelectedZonas(value)
    var payload = {};

    if (
      refTipoPrestador.current.value !== null &&
      refTipoPrestador.current.value !== "Todas"
    ) {
      payload.tipoPrestador = refTipoPrestador.current.value;
    }

    if (
      refEspecialidad.current.value !== null &&
      refEspecialidad.current.value !== "Todas"
    ) {
      payload.especialidad = refEspecialidad.current.value;
    }

    if (refZona.current.value !== null && refZona.current.value !== "Todas") {
      payload.zona = refZona.current.value;
    }

    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");

    // const { data } = await fetch(`external-cartilla/barrios?tipoPrestador=${refTipoPrestador.current.value}&especialidad=${refEspecialidad.current.value}&zona=${refZona.current.value}`, "GET");
    const { data } = await fetch(
      `external-cartilla/barrios?${queryString}`,
      "GET"
    );
    setBarrios(csv2json(data.result));
    // setPlanes(null)
    setSelectedZonas(value);
  };

  const handleChangeBarrios = async (e) => {
    const value = e.target.value;
    // const { data } = await fetch(`Barrios?tipoPrestador=${selectedPrestadores}&especialidad=${selectedEspecialidades}&zona=${selectedZonas}`, "GET");
    setSelectedBarrios(value);
  };

  const handleChangePlanes = (e) => {
    const value = e.target.value;
    setSelectedPlanes(value);
    // getData2();
  };

  const handleBuscarXTexto = () => {
    setEspecialidad(false);
    setSelectedPrestadores(null);
    setSelectedEspecialidades(null);
    setSelectedZonas(null);
    setSelectedBarrios(null);
    setSelectedPlanes(null);
  };

  const handleBuscarSubmit = async (event) => {
    event.preventDefault();
    handleBuscar();
  };

  const handleBuscar = async () => {
    setLoading(true);
    //const { data } = await fetch(`Cartilla?tipoPrestador=${selectedPrestadores}&especialidad=${selectedEspecialidades}&zona=${selectedZonas}&barrio=${selectedBarrios}&plan=${selectedPlanes}`, "GET");
    //   if(selectedPrestadores){
    //     const { data } = await fetch(`external-cartilla/search?tipoPrestador=${selectedPrestadores}`, "GET");
    //     setCartilla(data.result)
    //     setLoading(false);
    //     executeScroll();
    //   }else
    //   if(selectedPrestadores && selectedEspecialidades){
    //     const { data } = await fetch(`external-cartilla/search?tipoPrestador=${selectedPrestadores}&especialidad=${selectedEspecialidades}`, "GET");
    //     setCartilla(data.result)
    //     setLoading(false);
    //     executeScroll();
    //   }else
    //   if(selectedPrestadores && selectedEspecialidades && selectedZonas){
    //     const { data } = await fetch(`external-cartilla/search?tipoPrestador=${selectedPrestadores}&especialidad=${selectedEspecialidades}&zona=${selectedZonas}`, "GET");
    //     setCartilla(data.result)
    //     setLoading(false);
    //     executeScroll();
    //   }else
    //   if(selectedPrestadores && selectedEspecialidades && selectedZonas && selectedBarrios){
    //     const { data } = await fetch(`external-cartilla/search?tipoPrestador=${selectedPrestadores}&especialidad=${selectedEspecialidades}&zona=${selectedZonas}&barrio=${selectedBarrios}`, "GET");
    //     setCartilla(data.result)
    //     setLoading(false);
    //     executeScroll();
    //   }else
    //   if(selectedPrestadores && selectedEspecialidades && selectedZonas && selectedBarrios && selectedPlanes){
    //     const { data } = await fetch(`external-cartilla/search?tipoPrestador=${selectedPrestadores}&especialidad=${selectedEspecialidades}&zona=${selectedZonas}&barrio=${selectedBarrios}&plan=${selectedPlanes}`, "GET");
    //     setCartilla(data.result)
    //     setLoading(false);
    //     executeScroll();
    //   }else{
    //     if(searchText){
    //       const { data } = await fetch(`external-cartilla/search?search=${searchText}`, "GET");
    //       setCartilla(data.result)
    //       setLoading(false);
    //       executeScroll();
    //     }
    //   else{
    //     const { data } = await fetch(`external-cartilla/search`, "GET");
    //     setCartilla(data.result)
    //     setLoading(false);
    //     executeScroll();
    //   }
    // }

    if (searchText && !especialidad) {
      const { data } = await fetch(
        `external-cartilla/search?search=${encodeURIComponent(searchText)}`,
        "GET"
      );
      setCartilla(data.result);
    } else {
      //alert("CONDICIONES");
      var payload = {};
      // if(selectedPrestadores!=="Todas" && selectedPrestadores!=null){
      //   payload.tipoPrestador=selectedPrestadores;
      // }

      // if(selectedEspecialidades!=="Todas" && selectedEspecialidades!=null){
      //   payload.especialidad=selectedEspecialidades;
      // }

      // if(selectedZonas!=="Todas" && selectedZonas!=null)
      // {
      //   payload.zona=selectedZonas;
      // }

      // if(selectedBarrios!=="Todos" && selectedBarrios!=null){
      //   payload.barrio=selectedBarrios;
      // }

      // if(selectedPlanes!=="Todos" && selectedPlanes!=null)
      // {
      //   payload.plan=selectedPlanes;
      // }

      if (
        refTipoPrestador.current.value !== "Todas" &&
        refTipoPrestador.current.value != null
      ) {
        payload.tipoPrestador = refTipoPrestador.current.value;
      }

      if (
        refEspecialidad.current.value !== "Todas" &&
        refEspecialidad.current.value != null
      ) {
        payload.especialidad = refEspecialidad.current.value;
      }

      if (refZona.current.value !== "Todas" && refZona.current.value != null) {
        payload.zona = refZona.current.value;
      }

      if (
        refBarrio.current.value !== "Todos" &&
        refBarrio.current.value != null
      ) {
        payload.barrio = refBarrio.current.value;
      }

      if (refPlan.current.value !== "Todos" && refPlan.current.value != null) {
        payload.plan = refPlan.current.value;
      }

      var queryString = Object.keys(payload)
        .map((key) => key + "=" + payload[key])
        .join("&");

      // console.log("QueryString",queryString);

      const { data } = await fetch(
        `external-cartilla/search?${queryString}`,
        "GET"
      );
      setCartilla(data.result);
    }

    setLoading(false);
    executeScroll();
  };

  return (
    <>
      {loader ? (
        <CommonLoader />
      ) : (
        <section
          className="position-relative"
          style={{
            overflow: "hidden",
            minHeight: "calc(100vh - 25px - 100px - 225px)",
          }}
        >
          {/* <div className="container-right-buttons d-flex flex-column cartilla-buttons-desktop">
            <div className="container-right-buttons d-flex flex-column">
              <button
                onClick={() => setEspecialidad(true)}
                type="button"
                className={
                  especialidad ? "btn2-lateralr-color5" : "btn2-lateralr-color2"
                }
              >
                Buscar por <br /> especialidad
              </button>
              <button
                onClick={() => handleBuscarXTexto()}
                type="button"
                className={
                  especialidad ? "btn2-lateralr-color2" : "btn2-lateralr-color5"
                }
              >
                Buscar por <br /> texto
              </button>
            </div>
          </div> */}

          {especialidad ? (
            <div className="container pt-5 pb-5">
              <div className="row">
                <div className="col-lg-9 m-auto">
                  <div className="global_subtitle bold">Cartilla Médica</div>
                  <hr className="hr6" />
                  {/* <div className="cartilla-buttons-mobile">
                    <button
                      onClick={() => setEspecialidad(true)}
                      type="button"
                      className={
                        especialidad
                          ? "btn2-lateralr-color5"
                          : "btn2-lateralr-color2"
                      }
                    >
                      Buscar por especialidad
                    </button>
                    <button
                      onClick={() => handleBuscarXTexto()}
                      type="button"
                      className={
                        especialidad
                          ? "btn2-lateralr-color2"
                          : "btn2-lateralr-color5"
                      }
                    >
                      Buscar por texto
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 m-auto">
                  <div className="card b-radius-20 p-5" id="card-cartilla">
                    <form className="section_contacto_form">
                      {/* <h2 className="section_contacto_form_titulo  mb-5">
                        <b>Buscar por especialidad</b>
                      </h2> */}
                      <div className="mb-4 row align-items-center">
                        <div className="col-lg-2">
                          <label className="categoria_texto">
                            Tipo de prestador
                          </label>
                        </div>
                        <div className="col-lg-10">
                          <select
                            ref={refTipoPrestador}
                            className="form-control b-radius-20 shadow-blue"
                            onChange={(e) => handleChangePrestadores(e)}
                          >
                            <option>Todas</option>
                            {prestadores &&
                              prestadores.map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                        <div className="col-lg-2">
                          <label className="categoria_texto">
                            Especialidad
                          </label>
                        </div>
                        <div className="col-lg-10">
                          <select
                            ref={refEspecialidad}
                            className="form-control b-radius-20 shadow-blue"
                            onChange={(e) => handleChangeEspecialidades(e)}
                          >
                            <option>Todas</option>
                            {especialidades &&
                              especialidades.map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                        <div className="col-lg-2">
                          <label className="categoria_texto">Zona</label>
                        </div>
                        <div className="col-lg-10">
                          <select
                            ref={refZona}
                            className="form-control b-radius-20 shadow-blue"
                            onChange={(e) => handleChangeZonas(e)}
                          >
                            <option>Todas</option>
                            {zonas &&
                              zonas.map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                        <div className="col-lg-2">
                          <label className="categoria_texto">
                            Barrio / Localidad
                          </label>
                        </div>
                        <div className="col-lg-10">
                          <select
                            ref={refBarrio}
                            className="form-control b-radius-20 shadow-blue"
                            onChange={(e) => handleChangeBarrios(e)}
                          >
                            <option value="Todos">Todos</option>
                            {barrios &&
                              barrios.map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="mb-4 row align-items-center">
                        <div className="col-lg-2">
                          <label className="categoria_texto">Plan</label>
                        </div>
                        <div className="col-lg-10">
                          <select
                            ref={refPlan}
                            className="form-control b-radius-20 shadow-blue"
                            onChange={(e) => handleChangePlanes(e)}
                          >
                            <option value="Todos">Todos</option>
                            {planes &&
                              planes.map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end container-button-search">
                        <button
                          type="button"
                          className="btn2-color2-r"
                          disabled={loading}
                          onClick={() => handleBuscar()}
                        >
                          {loading ? "Buscando..." : "Buscar"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="container pt-5 pb-5">
                <div className="row">
                  <div className="col-lg-9 m-auto">
                    <div className="global_subtitle bold">Cartilla Médica</div>
                    <hr className="hr6" />
                    {/* <div className="cartilla-buttons-mobile">
                      <button
                        onClick={() => setEspecialidad(true)}
                        type="button"
                        className={
                          especialidad
                            ? "btn2-lateralr-color5"
                            : "btn2-lateralr-color2"
                        }
                      >
                        Buscar por especialidad
                      </button>
                      <button
                        onClick={() => handleBuscarXTexto()}
                        type="button"
                        className={
                          especialidad
                            ? "btn2-lateralr-color2"
                            : "btn2-lateralr-color5"
                        }
                      >
                        Buscar por texto
                      </button>
                    </div> */}
                  </div>
                  <form onSubmit={handleBuscarSubmit}>
                    <div className="row">
                      <div className="col-lg-9 m-auto">
                        <div className="card b-radius-20 p-5">
                          <h2 className="section_contacto_form_titulo  mb-5">
                            <b>Buscar por Texto</b>
                          </h2>
                          <div className="mb-4 row align-items-center">
                            <div className="col-lg-2">
                              <label className="categoria_texto">
                                Buscar por Texto
                              </label>
                            </div>
                            <div className="col-lg-10">
                              <input
                                type="text"
                                onChange={(e) => setSearchText(e.target.value)}
                                className="form-control b-radius-20 shadow-blue"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-end container-button-search">
                            <button
                              type="button"
                              className="btn2-color2-r"
                              disabled={loading}
                              onClick={() => handleBuscar()}
                            >
                              {loading ? "Buscando..." : "Buscar"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          <div className="container">
            <div className="row mt-0">
              <div className="col-lg-9 m-auto">
                <div className="contenido-bottom">
                  <h4 style={{ fontWeight: "600" }}>Información Importante:</h4>
                  <br />
                  <p>
                    Los socios de los planes 3001E, 301, 301CS, 301E,400, 400CS,
                    200CS, 300CS, 200, 300, 3000E, 4000E, 4000R, 120, R, 1E, 1,
                    11 deben de consultar la cartilla del Plan Privilege.
                  </p>
                  <p>
                    Los socios de los planes 150CS, C, C1, D, D1, D66, 150CS,
                    150C, 150 CE, 17, 8, 14, 18, 4, 904, 2000E, College y Oro
                    deben de consultar la cartilla del Plan Premium.
                  </p>
                  <p>
                    Los socios de los planes 10, 100, 100CS, 10E y Plata deben
                    de consultar la cartilla del Plan Classic.
                  </p>
                </div>
                <hr className="hr6" />
              </div>
            </div>

            <div className="refTable" ref={refTable}>
              {cartilla && (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-9 m-auto">
                      <div style={{ color: "#1f5f9d" }} className="mb-3">
                        {cartilla.length} Resultados de la búsqueda.
                      </div>

                      <table className="table table-cartilla">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Especialidad</th>
                            <th>Direccion</th>
                            <th>Localidad</th>
                            <th>Telefono</th>
                            <th>&nbsp;</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartilla.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{normalizar(data.nombre)}</td>
                                <td>{data.especialidad}</td>
                                <td>{data.direccion}</td>
                                <td>{data.barrio}</td>
                                <td>{data.telefono}</td>
                                <td>
                                  <a target="_blank" className="d-flex justify-content-center flex-column gap-1" rel="noreferrer" href={`https://www.google.com/maps?q=${data.direccion}, ${data.barrio}, Argentina`}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} color="#0f86c3" />
                                     <p className="view-map">Ver Mapa</p>
                                  </a>
                                </td>
                                <td></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default CartilaPage;
