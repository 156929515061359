import * as Yup from "yup";

export const validateModel = Yup.object().shape({
  nombre: Yup.string().required("Campo obligatorio"),
  apellido: Yup.string().required("Campo obligatorio"),
  email: Yup.string().email("Email inválido").required("Campo obligatorio"),
  celular: Yup.number()
    .required("Campo obligatorio")
    .typeError("Ingrese un numero válido"),
  celular_prefix: Yup.number()
    .required("Campo obligatorio")
    .typeError("Ingrese un numero válido"),
  razon: Yup.string().required("Campo obligatorio"),
  tipoUsuario: Yup.string().required("Campo obligatorio"),
  area: Yup.string().required("Campo obligatorio"),
  mensaje: Yup.string().required("Campo obligatorio"),
  aceptoPrivacidad: Yup.bool().required("Campo obligatorio"),
  validateAceptoPrivacidad: Yup.string().when("aceptoPrivacidad", {
    is: false,
    then: Yup.string().required("Campo obligatorio"),
  }),
});
