import axios from 'axios';
import storage from './storage';
import { BASE_URL, BASE_URL_SITIO_INSTITUCIONAL, BASE_URL_API_CARTILLA } from './contants';

// eslint-disable-next-line import/prefer-default-export
export const fetch = async (endpoint, method, data, options = { ContentType: 'application/json' }) => {
  const token = await storage.get('token');
  const URL = `${BASE_URL}/${endpoint}`;
  const headers = {
    'Content-Type': options.ContentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};

export const fetchExternal = async (endpoint, method, data, options = { ContentType: 'application/json' }) => {
  const token = await storage.get('token');
  const URL = `${BASE_URL_SITIO_INSTITUCIONAL}/${endpoint}`;
  const headers = {
    'Content-Type': options.ContentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};

export const fetchCartilla = async (endpoint, method, data, options = { ContentType: 'application/json' }) => {
  const token = await storage.get('token');
  const URL = `${BASE_URL_API_CARTILLA}/${endpoint}`;
  const headers = {
    'Content-Type': options.ContentType,
  };
    headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2NTYwNzU1NjAsImFjciI6IjIyMWQ2ZmZlLWY2NjEtNDg3ZS04M2QzLTZlMjdhODg4NWZmMCIsImV4cCI6MTY1ODY2NzU2MCwiaWF0IjoxNjU2MDc1NTYwLCJpc3MiOiJSVzVOTkhWaFRqSnJhMjVxUjBaaU16WmpWbXhTVkhjcmMyMUhWMlZvVDNoV2VHMVFaamx6T1U5Rk1FUTJZVE54TURoUmRYWnhVbkJ3T0Vkck5EbFhiblYxYVhKRFZsSXJXRWc1TDJwT1dUVjVXbWcyZVdjOVBRPT0ifQ.3NaNeXKvzor2wIe5n-95kdeQGgHLi4wzEiLSog-hy_U`;

  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};