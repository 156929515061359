import LocationIcon from "../../assets/img/icon-location-green.png";
import Phone from "../../assets/img/icon-phone-green.png";
import ArrowBlue from "../../assets/img/arrow-green.png";

import "./hb-red-centros.scss";

import React from "react";
import dompurify from "dompurify";
import { Link } from "react-router-dom";

import DynamicCommonLoader from "../../components/common-loader/dynamicCommonLoader";
import { fetchExternal } from "../../utils/fetch";

const HbRedCentrosPage = () => {
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await fetchExternal(
        `centros-medicos?_locale=es&onOff=true&_eliminado=false&_sort=orden:ASC`,
        "GET"
      );
      setContenido(data);
      setLoader(false);

      console.log(data);
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <section
      className="hb-red-centros position-relative"
      style={{ minHeight: "calc(100vh - 25px - 100px - 225px)" }}
    >
      <div className="right-buttons hb-red-buttons-desktop">
        {contenido &&
          contenido.map((data, index) => {
            return (
              <Link
                to={"ver/" + data.id}
                type="button"
                key={index}
                className="btn2-lateralr-color2"
              >
                {data.nombre}
              </Link>
            );
          })}
      </div>

      <div className="container">
        <div className="row pt-5 pb-2 ">
          <div className="col-lg-9">
            <h2 className="global_subtitle bold">
              Hospital Británico Central y Red de Centros Propios
            </h2>
            <hr className="hr6" />

            <div className="right-buttons hb-red-buttons-mobile">
              {contenido &&
                contenido.map((data, index) => {
                  return (
                    <Link
                      to={"ver/" + data.id}
                      type="button"
                      key={index}
                      className="btn2-lateralr-color2"
                    >
                      {data.nombre}
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9">
            {loader ? (
              <DynamicCommonLoader dynamic={"500px"} />
            ) : (
              <>
                <div className="row">
                  {contenido &&
                    contenido.map((data, index) => {
                      return (
                        <div key={index} className="col-lg-6 pb-2">
                          <div className="card card-centros-medicos b-radius-20 mb-4 p-2">
                            <div className="card-body">
                              <div className="card-foto b-radius-0">
                                <img
                                  src={data?.imagen?.url}
                                  className={`w-100 foto-centro ${
                                    data.uuid == "lanus"
                                      ? "img-cover-bottom"
                                      : "img-cover-normal"
                                  }`}
                                  alt="..."
                                />
                              </div>
                              {/* <hr className="separador_reusable_components"/> */}
                              <h5 className="card-title mt-2">{data.nombre}</h5>

                              <div className="card-text row">
                                <div className="col-1">
                                  <img src={LocationIcon} alt="location" />
                                </div>
                                <div className="col-11">
                                  <b>{data.direccion}</b>
                                  <br />
                                  {data.localidad}
                                </div>
                              </div>
                              <p className="card-text row">
                                <div className="col-1">
                                  <img src={Phone} alt="phone" />
                                </div>
                                <div className="col-11">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(data.info_contacto),
                                    }}
                                  />
                                </div>
                              </p>
                            </div>
                            <div className="card-footer d-flex justify-content-end bg-transparent border-0">
                              <Link
                                to={"ver/" + data.id}
                                className="stretched-link"
                              >
                                <img src={ArrowBlue} alt="arrow blue" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default HbRedCentrosPage;
