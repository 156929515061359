import * as Yup from "yup";

export const validateModel = Yup.object().shape({
  nombre: Yup.string()
    .required("Campo obligatorio")
    .max(25, "Como máximo 25 caracteres"),
  apellido: Yup.string()
    .required("Campo obligatorio")
    .max(25, "Como máximo 25 caracteres"),
  email: Yup.string().email("Email inválido").required("Campo obligatorio"),
  // edad: Yup.number()
  //   .required("Campo obligatorio")
  //   .typeError("Ingrese un numero valido")
  //   .test("len", "Como máximo 3 caracteres", function (value) {
  //     const length = String(value)?.length;
  //     return length < 4;
  //   })
  //   .min(0, "No se permiten valores negativos"),
  celular: Yup.number()
    .required("Campo obligatorio")
    .typeError("Ingrese un numero valido")
    .test("len", "Como máximo 8 caracteres", function (value) {
      const length = String(value)?.length;
      return length < 9;
    })
    .min(0, "No se permiten valores negativos"),
  celular_prefix: Yup.number()
    .required("Campo obligatorio")
    .typeError("Ingrese un numero valido")
    .test("len", "Como máximo 4 caracteres", function (value) {
      const length = String(value)?.length;
      return length < 5;
    })
    .min(0, "No se permiten valores negativos"),
  // mensaje: Yup.string()
  //   .required("Campo obligatorio")
  //   .max(500, "Como máximo 500 caracteres"),
  aceptoPrivacidad: Yup.bool().required("Campo obligatorio"),
  validateAceptoPrivacidad: Yup.string().when("aceptoPrivacidad", {
    is: false,
    then: Yup.string().required("Campo obligatorio"),
  }),
});
