/* eslint-disable eqeqeq */
import React from "react";
import { fetch } from "../../utils/fetch";
import "./prestadores.scss";
import ArrowGreen from "../../assets/img/arrow_green.png";
import { Link } from "react-router-dom";
import CommonLoader from "../../components/common-loader";
import DynamicCommonLoader from "../../components/common-loader/dynamicCommonLoader";

const NovedadesPage = () => {
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const recorrido=["a","b","c","c","a","b","b","c","a"];
  // const getData = async () => {
  //     const {data} = await fetch(`novedades?_locale=es`, 'GET' );

  //     setContenido(data)
  // }

  React.useEffect(() => {
    fetch(
      `prestadores?_locale=es&onOff=true&eliminado=false&_sort=orden:ASC`,
      "GET"
    )
      .then((res) => {
        setContenido(res.data);
      })
      .catch((error) => {
        setLoader(false);
        /*renderizo un error generico*/
        // console.log(error)
      })
      .finally(() => {
        setLoader(false);
      });

    // getData()
  }, []);

  /*
agregar parametros ingresados en el CMS dentro del fetch

    -opc1
    no lleva foto
    lleva titulo
    lleva descripcion corta
    */
  function Opc1({ fila, columna, data }) {
    return (
      <div
        className={`card b-radius-10 p-2 card-novedades ${
          "border-green"
        } border-20 widht `}
      >      
        <div className="card-body">
          {/* Titulo */}
          <div className="card-title">
            {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}
          </div>
          <hr className="hr6-sm" />
          {/* Descripcion Corta */}
          <div className="card-descripcion-corta opc1">
            {data.descripcionCorta.substr(0, 80)}
            {data.titulo.length > 80 && <>...</>}
          </div>
        </div>
        <div className="card-footer d-flex bg-transparent border-0 justify-content-end">
          {fila == columna ? (
            <Link to={{ pathname: `/prestadores/${data.slug}` }}>
              {" "}
              <img src={ArrowGreen} alt="arrow" />{" "}
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/prestadores/${data.slug}`,
                aboutProps: { name: "hellow" },
              }}
            >
              {" "}
              <img src={ArrowGreen} alt="arrow" />{" "}
            </Link>
          )}
        </div>
      </div>
    );
  }
  /*
    -opc2
    lleva foto
    lleva titulo
    no lleva descripcion corta
    */
  function Opc2({ fila, columna, data }) {
    return (
      <div
        className={`card b-radius-10 p-2 card-novedades ${
          fila == columna && "bg-azul"
        } border-0 `}
      >      
        <div className="card-body">
          {/* Foto */}
          <div className="card-foto opc2">
            <img
              src={data?.imagen?.url}
              alt="novedad"
              className="b-radius-10"
            />
          </div>
          {/* Titulo */}
          <div className="card-title opc2">
            {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}             
             {/* {data.descripcionCorta.substr(0, 80)} */}
          </div>
        </div>
        <div className="card-footer opc2 d-flex bg-transparent border-0 justify-content-end">
          {fila == columna ? (
            <Link to={"/prestadores/" + data.slug}>
              {" "}
              <img src={ArrowGreen} alt="arrow" />{" "}
            </Link>
          ) : (
            <Link to={"/prestadores/" + data.slug}>
              {" "}
              <img src={ArrowGreen} alt="arrow" />{" "}
            </Link>
          )}
        </div>
      </div>
    );
  }
  /*
    -opc3
    no lleva foto
    lleva titulo
    lleva descripcion corta
    */
  function Opc3({ fila, columna, data }) {
    return (
        <div className={`card b-radius-10 p-2 card-novedades border-0 `}>        
          <div className="card-body">
            {/* Titulo */}
            <div className="card-title">
              {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}
            </div>
            <hr className="hr6-sm" />
            {/* Descripcion Corta */}
            <div className="card-descripcion-corta opc1">
              {data.descripcionCorta.substr(0, 80)}
              {data.titulo.length > 80 && <>...</>}
            </div>
          </div>
          <div className="card-footer d-flex bg-transparent border-0 justify-content-end">
            {fila == columna ? (
              <Link to={{ pathname: `/prestadores/${data.slug}` }}>
                {" "}
                <img src={ArrowGreen} alt="arrow" />{" "}
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/prestadores/${data.slug}`,
                  aboutProps: { name: "hellow" },
                }}
              >
                {" "}
                <img src={ArrowGreen} alt="arrow" />{" "}
              </Link>
            )}
          </div>
        </div>
      );

  }

  return (
    <div>
        <div style={{background: "white", position: "absolute", width: "2vw", top: 0, right: 0, height: "60vh", zIndex: 5000}}></div>
        <div className="prestadoresPg">
          {loader ? (
            <DynamicCommonLoader dynamic={"225px"} />
          ) : (
            <section className="bg-novedades">
              <div className="container">
                <div className="row pt-5 pb-3">
                  <div className="col-lg-12">
                    <h2 className="global_title bold">Novedades</h2>
                    <hr className="hr6 w-50" />
                  </div>
                </div>
                <div className="row">
                  {contenido &&
                    contenido.map((data, index) => {
                      let fila = parseInt(index / 3) % 3;
                      let columna = index % 3;
                      var opcion=recorrido[index%recorrido.length];

                      // console.log("INDEX: "+index+", FILA: "+fila+", COLUMNA:"+columna);
                      return (
                        <div className="col-lg-4 mb-4" key={index}>
                          {/* Validaciones Opc1  */}
                          {opcion == "a" && (
                            <Opc1 data={data} fila={fila} columna={columna} />
                          )}
                          {/* Validaciones Opc2  */}
                          {opcion === "b" && (
                            <Opc2 data={data} fila={fila} columna={columna} />
                          )}
                          {/* Validaciones Opc3  */}
                          {opcion === "c" && (
                            <Opc3 data={data} fila={fila} columna={columna} />
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </section>
          )}
        </div>
    </div>
  );
};
export default NovedadesPage;
