import { useState, useContext, lazy, Suspense } from 'react';
import { Outlet } from "react-router-dom";
import ErrorBoundary from '../../components/exception/error';
import FooterComponent from '../../components/footer';
import Loader from '../../components/loader';
import NavbarComponent from '../../components/navbar';
import NavbarMobileComponent from '../../components/navbar-mobile';
import { GlobalContextMemorySpace } from '../../context/globalContext';


const Layout = ({ children }) => {

    const [loader, setLoader] = useState(false)

    const {MainLoader, showLoaderHomePage, NavBarSectionFetchData} = useContext(GlobalContextMemorySpace);

    //const [scroll, setScroll] = useState("hidden")

    //document.body.style.overflow = "scroll"

    setTimeout(() => {
        setLoader(false)
        //window.scrollTo(0, 0);
    }, 2000);

    return (
        <>
            {MainLoader && showLoaderHomePage  ? 
            
            <Loader />
            
            : 
            
            <>
                {/* Navbar */}
                <div className='nav-desktop'>
                    <NavbarComponent NavBarSectionFetchData={NavBarSectionFetchData}/>
                </div>
                <div className='nav-mobile'>                
                    <NavbarMobileComponent NavBarSectionFetchData={NavBarSectionFetchData} />
                </div>

                {/* Body */}
                <ErrorBoundary>
                    <main>
                        <Outlet />
                        {children}
                    </main>
                </ErrorBoundary>

                {/* Footer */}
                <FooterComponent/>
            </>
            }
            
            
        </>
    );
}
export default Layout;