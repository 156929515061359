export const mySortMenuItems = (menu) => {
  menu.sort((a, b) => {
    if (a.order === undefined && b.order === undefined) {
      return 0;
    } else if (a.order === undefined) {
      return 1;
    } else if (b.order === undefined) {
      return -1;
    } else if (a.order === null && b.order === null) {
      return 0;
    } else if (a.order === null) {
      return 1;
    } else if (b.order === null) {
      return -1;
    } else {
      return a.order - b.order;
    }
  });
};
