import "./style.css";
import botonPopUp from "../../assets/img/botonPopUp.svg";
import React from "react";
import { Link } from "react-router-dom";
import { fetch } from "../../utils/fetch";
import ReactGA from "react-ga4";

const getSizeImg = (url, callback) => {
  const img = new Image();

  img.onload = function () {
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    callback(null, width, height);
  };

  img.src = url;
};

const isDesktopMode = window.innerWidth > window.screen.availWidth;

export function PopUpComponent() {
  const [open, setOpen] = React.useState(false);
  const [contenido, setContenido] = React.useState();
  const [isLarge, setIsLarge] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= 768 || isDesktopMode
  );

  const accionPopup = () => {
    ReactGA.event({
      category: `Novedades`,
      action: "Clic",
      label: "Clic en Pop-Up",
    });
  };

  const cerrarPopUp = () => {
    setOpen(false);
    accionPopup();
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768 || isDesktopMode);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (contenido?.imagen?.url) {
      getSizeImg(contenido?.imagen?.url, (error, width, height) => {
        const windowHeight = window.innerHeight;
        if (height == width || height > width) {
          setIsLarge(true);
        }
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contenido]);

  React.useEffect(() => {
    const getData = async () => {
      let url = `popups/habilitado/get`;

      const { data } = await fetch(url, "GET");
      let pop = sessionStorage.getItem("popup");

      if (pop != "" && data?.popup) {
        if (pop != JSON.stringify(data.popup)) {
          setContenido(data?.popup);

          sessionStorage.setItem("popup", JSON.stringify(data.popup));
          setTimeout(() => {
            setOpen(true);
          }, 3000);
        }
      } else if (pop == "") {
        setContenido(data?.popup);

        sessionStorage.setItem("popup", JSON.stringify(data.popup));
        setTimeout(() => {
          setOpen(true);
        }, 3000);
      }
    };
    getData();
  }, []);

  if (!open) return null;
  return (
    <div className="contenedor">
      <div className="overlay-style" />
      <div className="modal-style">
        <img
          src={botonPopUp}
          className="close-modal"
          onClick={(e) => setOpen(false)}
        />
        {contenido?.enlace_interno ? (
          <Link
            to={contenido?.enlace}
            className="zona-click"
            onClick={accionPopup}
          ></Link>
        ) : (
          <a
            href={contenido?.enlace}
            target="_blank"
            onClick={(e) => cerrarPopUp}
            className="zona-click"
          ></a>
        )}

        <img
          src={contenido?.imagen?.url}
          style={{ borderRadius: 10 }}
          className={`img-fluid img-fluid-mobile ${
            isLarge & !isMobile && "is-large"
          }`}
        />
      </div>
    </div>
  );
}
